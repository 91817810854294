import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppLogoutComponent} from './app.logout.component';


@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent, children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        redirectTo: 'ventas/ver-comprobantes',
                        // canActivate: [AuthGuard],
                    },
                    {
                        path: 'redirect',
                        loadChildren: () => import('@app/components/view/redirect-page/redirect-page.module').then(m => m.RedirectPageModule)
                    },
                    {
                        path: 'dashboard',
                        loadChildren: () => import('@app/components/view/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'empresas',
                        loadChildren: () => import('@app/components/view/empresas/empresas.module').then(m => m.EmpresasModule)
                    },
                    {
                        path: 'plantillas',
                        loadChildren: () => import('@app/components/view/ticket-designs/ticket-designs.module').then(m => m.TicketDesignsModule)
                    },
                    {
                        path: 'cuotas',
                        loadChildren: () => import('@app/components/view/administrador/administrador.module').then(m => m.AdministradorModule)
                    },
                    {
                        path: 'control-empresas',
                        loadChildren: () => import('@app/components/view/control-empresas/control-empresas.module').then(m => m.ControlEmpresasModule)
                    },
                    {
                        path: 'puntos-venta',
                        loadChildren: () => import('@app/components/view/puntos-venta/puntos-venta.module').then(m => m.PuntosVentaModule)
                    },
                    {
                        path: 'accesos-directos',
                        loadChildren: () => import('@app/components/view/shortcuts/shortcuts.module').then(m => m.ShortcutsModule)
                    },
                    {
                        path: 'usuarios',
                        loadChildren: () => import('@app/components/view/usuarios/usuarios.module').then(m => m.UsuariosModule)
                    },
                    {
                        path: 'perfiles',
                        loadChildren: () => import('@app/components/view/perfiles/perfiles.module').then(m => m.PerfilesModule)
                    },
                    {
                        path: 'certificados',
                        loadChildren: () => import('@app/components/view/certificados/certificados.module').then(m => m.CertificadosModule)
                    },
                    {
                        path: 'bancos',
                        loadChildren: () => import('@app/components/view/bancos/bancos.module').then(m => m.BancosModule)
                    },
                    {
                        path: 'horarios',
                        loadChildren: () => import('@app/components/view/horarios/horarios.module').then(m => m.HorariosModule)
                    },
                    {
                        path: 'ciudades',
                        loadChildren: () => import('@app/components/view/ciudades/ciudades.module').then(m => m.CiudadesModule)
                    },
                    {
                        path: 'formas-pago',
                        loadChildren: () => import('@app/components/view/formas-pago/formas-pago.module').then(m => m.FormasPagoModule)
                    },
                    {
                        path: 'estaciones-venta',
                        loadChildren: () => import('@app/components/view/estacion-venta/estacion-venta.module').then(m => m.EstacionVentaModule)
                    },
                    {
                        path: 'cuentas-bancarias',
                        loadChildren: () => import('@app/components/view/cuentas-bancarias/cuentas-bancarias.module').then(m => m.CuentasBancariasModule)
                    },
                    {
                        path: 'marcas',
                        loadChildren: () => import('@app/components/view/marcas/marcas.module').then(m => m.MarcasModule)
                    },
                    {
                        path: 'categorias',
                        loadChildren: () => import('@app/components/view/categorias/categorias.module').then(m => m.CategoriasModule)
                    },
                    {
                        path: 'series',
                        loadChildren: () => import('@app/components/view/series/series.module').then(m => m.SeriesModule)
                    },
                    {
                        path: 'clientes',
                        loadChildren: () => import('@app/components/view/clientes/clientes.module').then(m => m.ClientesModule)
                    },
                    {
                        path: 'empleados',
                        loadChildren: () => import('@app/components/view/empleados/empleados.module').then(m => m.EmpleadosModule)
                    },
                    {
                        path: 'proveedores',
                        loadChildren: () => import('@app/components/view/proveedores/proveedores.module').then(m => m.ProveedoresModule)
                    },
                    {
                        path: 'impresoras',
                        loadChildren: () => import('@app/components/view/impresoras/impresoras.module').then(m => m.ImpresorasModule)
                    },
                    {
                        path: 'productos',
                        loadChildren: () => import('@app/components/view/productos/productos.module').then(m => m.ProductosModule)
                    },
                    {
                        path: 'preparaciones',
                        loadChildren: () => import('@app/components/view/preparaciones/preparaciones.module').then(m => m.PreparacionesModule)
                    },
                    {
                        path: 'grupos',
                        loadChildren: () => import('@app/components/view/grupos/grupos.module').then(m => m.GruposModule)
                    },
                    {
                        path: 'ventas',
                        loadChildren: () => import('@app/components/view/ventas/ventas.module').then(m => m.VentasModule)
                    },
                    {
                        path: 'whatsapp',
                        loadChildren: () => import('@app/components/view/whatsapp/whatsapp.module').then(m => m.WhatsappModule)
                    },
                    {
                        path: 'compras',
                        loadChildren: () => import('@app/components/view/compras/compras.module').then(m => m.ComprasModule)
                    },
                    {
                        path: 'restaurante',
                        loadChildren: () => import('@app/components/view/restaurante/restaurante.module').then(m => m.RestauranteModule)
                    },
                    {
                        path: 'guia-remision',
                        loadChildren: () => import('@app/components/view/facturacion/guia-remision/guia-remision.module').then(m => m.GuiaRemisionModule)
                    },
                    {
                        path: 'facturacion-electronica/comunicacion-baja',
                        loadChildren: () => import('@app/components/view/facturacion/comunicacion-baja/comunicacion-baja.module').then(m => m.ComunicacionBajaModule)
                    },
                    {
                        path: 'facturacion-electronica/resumen-diario',
                        loadChildren: () => import('@app/components/view/facturacion/resumen-diario/resumen-diario.module').then(m => m.ResumenDiarioModule)
                    },
                    {
                        path: 'monitoreo',
                        loadChildren: () => import('@app/components/view/monitoreo/monitoreo.module').then(m => m.MonitoreoModule)
                    },
                    {
                        path: 'cuentas-por-cobrar',
                        loadChildren: () => import('@app/components/view/cuentas-por-cobrar/cuentas-por-cobrar.module').then(m => m.CuentasPorCobrarModule)
                    },
                    {
                        path: 'membresias',
                        loadChildren: () => import('@app/components/view/membresias/membresias.module').then(m => m.MembresiasModule)
                    },
                    {
                        path: 'inventario',
                        loadChildren: () => import('@app/components/view/inventario/inventario.module').then(m => m.InventarioModule)
                    },
                    {
                        path: 'inventario-ajustes',
                        loadChildren: () => import('@app/components/view/inventario-ajuste/inventario-ajuste.module').then(m => m.InventarioAjusteModule)
                    },
                    {
                        path: 'inventario-traslados',
                        loadChildren: () => import('@app/components/view/inventario-traslado/inventario-traslado.module').then(m => m.InventarioTrasladoModule)
                    },
                    {
                        path: 'reportes',
                        loadChildren: () => import('@app/components/view/reportes/reportes.module').then(m => m.ReportesModule)
                    },
                    {
                        path: 'caja',
                        loadChildren: () => import('@app/components/view/caja/caja.module').then(m => m.CajaModule)
                    },
                    {
                        path: 'mi-perfil',
                        loadChildren: () => import('@app/components/view/mi-perfil/mi-perfil.module').then(m => m.MiPerfilModule)
                    },
                    {
                        path: 'pagos',
                        loadChildren: () => import('@app/components/view/pagos/pagos.module').then(m => m.PagosModule)
                    },
                    {
                        path: 'cambiar-clave',
                        loadChildren: () => import('@app/components/view/cambiar-clave/cambiar-clave.module').then(m => m.CambiarClaveModule)
                    },
                    {
                        path: 'unidades',
                        loadChildren: () => import('@app/components/view/unidades/unidades.module').then(m => m.UnidadesModule)
                    },
                    {
                        path: 'monedas',
                        loadChildren: () => import('@app/components/view/monedas/monedas.module').then(m => m.MonedasModule)
                    },
                    {
                        path: 'mi-empresa',
                        loadChildren: () => import('@app/components/view/mi-empresa/mi-empresa.module').then(m => m.MiEmpresaModule)
                    },
                    {
                        path: 'configurar-punto-venta',
                        loadChildren: () => import('@app/components/view/configura-punto-venta/configura-punto-venta.module')
                            .then(m => m.ConfiguraPuntoVentaModule)
                    }


                ]
            },
            {path: 'login', loadChildren: () => import('@app/pages/login/login.module').then(m => m.LoginModule)},
            {path: 'logout', component: AppLogoutComponent},
            {
                path: 'consulta-comprobante',
                loadChildren: () => import('@app/pages/consulta-comprobantes/consulta-comprobantes.module').then(m => m.ConsultaComprobantesModule)
            },
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
