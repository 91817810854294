import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {CashRegister} from '../domain/cash-register';
import {CashRegisterDetail} from '@app/domain/cash-register-detail';
import {DomSanitizer} from '@angular/platform-browser';
import {Page} from '@app/domain/page';
import {TotalMovimientos} from '@app/domain/total-movimientos';
import {Printer} from '@app/domain/printer';
import {QzTrayService} from '@app/service/qz-tray.service';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';
import {TicketFee} from "@app/domain/ventas/ticket-fee";

@Injectable({
    providedIn: 'root'
})
export class CashRegisterService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient, private sanitizer: DomSanitizer,
                private qzEscPosService: QzEscPosService,
                private coffeEscPosService: CoffeEscPosService,
                private qzTrayService: QzTrayService) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/cash-register?${params}`);
    }

    aperturar(model: CashRegister): Observable<CashRegister> {
        return this.http.post<CashRegister>(`${this.baseUrl}/cash-register`, model);
    }

    closeCashRegister(id, model: any): Observable<CashRegister> {
        return this.http.patch<CashRegister>(`${this.baseUrl}/cash-register/operations/close?id=${id}`, model);
    }

    getCashRegisgerDetail(id): Observable<CashRegisterDetail> {
        return this.http.get<CashRegisterDetail>(`${this.baseUrl}/cash-register-detail/${id}`);
    }

    saveCashRegisgerDetail(model: any): Observable<CashRegister> {
        return this.http.post<CashRegister>(`${this.baseUrl}/cash-register-detail`, model);
    }

    updateCashRegisgerDetail(model: any): Observable<CashRegisterDetail> {
        return this.http.put<CashRegisterDetail>(`${this.baseUrl}/cash-register-detail`, model);
    }

    findAllDetails(params: any): Observable<CashRegisterDetail[]> {
        return this.http.get<CashRegisterDetail[]>(`${this.baseUrl}/cash-register-detail?${params}`);
    }

    findAllDetailsPage(params: any): Observable<Page<CashRegisterDetail>> {
        return this.http.get<Page<CashRegisterDetail>>(`${this.baseUrl}/cash-register-detail?${params}`);
    }

    totales(params: string): Observable<TotalMovimientos> {
        return this.http.get<TotalMovimientos>(`${this.baseUrl}/cash-register-detail/operations/totales?${params}`);
    }

    openPdf(idCaja, params, options): Observable<any> {
        return new Observable<any>(observer => {
            const url = `${environment.endpointApi}/public/pdf/cash-register/${idCaja}?${params}`;
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    if (options.escPos) {
                        if (options.clienteImpresion === 'QZ') {
                            this.qzEscPosService.cashRegisterEscPos(idCaja, params, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        } else {
                            this.coffeEscPosService.cashRegisterEscPos(idCaja, params, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        }
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                });
            });
        });
    }

    openPdfDetail(cashRegisterDetail: CashRegisterDetail, options: any): Observable<any> {
        const url = this.getUrlPdfDetail(cashRegisterDetail.id);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    if (options.escPos) {
                        if (options.clienteImpresion === 'COFFE') {
                            this.coffeEscPosService.cashRegisterDetailEscPos(cashRegisterDetail.id, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        } else {
                            observer.next(response);
                            observer.complete();
                        }
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                });
            });
        });
    }

    deleteDetail(id) {
        return this.http.delete<void>(`${this.baseUrl}/cash-register-detail/${id}`);
    }

    getUrlPdfDetail(id) {
        return `${environment.endpointApi}/public/pdf/cash-register-detail/${id}`;
    }

    printInQz(data, printer: Printer, escPos: boolean) {
        const options = {
            flavor: 'base64',
            escPos
        };
        this.qzTrayService.queue.next({printer, data, options});
    }
}
