import {Pipe, PipeTransform} from '@angular/core';

export const currencyList = [
    {codigoMoneda: 'PEN', moneda: 'Sol', simbolo: 'S/'},
    {codigoMoneda: 'USD', moneda: 'Dólar', simbolo: '$'},
    {codigoMoneda: 'EUR', moneda: 'Euro', simbolo: '‎€'},
    {codigoMoneda: 'JPY', moneda: 'Yen', simbolo: '¥'}
];

@Pipe({
    name: 'moneda'
})
export class MonedaPipe implements PipeTransform {

    transform(value: any, show?: any): any {
        const moneda = currencyList.find(c => c.codigoMoneda === value);
        if (show && show === 'moneda') {
            return moneda ? moneda.moneda : 'Desc.';
        } else {
            return moneda ? moneda.simbolo : 'Desc.';
        }
    }

}
