import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Page} from '@app/domain/page';
import {AccountsReceivable} from '@app/domain/accounts-receivable';
import {AccountsReceivableDetail} from '@app/domain/accounts-receivable-detail';
import {TotalCuentas} from "@app/domain/total-cuentas";


@Injectable({
    providedIn: 'root'
})
export class AccountsReceivableService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findById(id): Observable<AccountsReceivable> {
        return this.http.get<AccountsReceivable>(`${this.baseUrl}/accounts-receivable/${id}`);
    }

    totales(params): Observable<TotalCuentas> {
        return this.http.get<TotalCuentas>(`${this.baseUrl}/accounts-receivable/operations/totales?${params}`);
    }

    findDetailById(id): Observable<AccountsReceivableDetail> {
        return this.http.get<AccountsReceivableDetail>(`${this.baseUrl}/accounts-receivable-detail/${id}`);
    }

    findAllPage(params: string): Observable<Page<AccountsReceivable>> {
        return this.http.get<any>(`${this.baseUrl}/accounts-receivable?${params}`);
    }

    findAllDetailByAccountReceivableId(id): Observable<AccountsReceivableDetail[]> {
        return this.http.get<any>(`${this.baseUrl}/accounts-receivable-detail?id=${id}`);
    }

    saveDetail(model: AccountsReceivableDetail, params): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/accounts-receivable-detail?${params}`, model);
    }

    updateDetail(model: AccountsReceivableDetail, params): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable-detail?${params}`, model);
    }

    extend(id, dias): Observable<void> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable/operations/extend/${id}?dias=${dias}`, null);
    }

    cancel(id): Observable<void> {
        return this.http.put<any>(`${this.baseUrl}/accounts-receivable/operations/cancel/${id}`, null);
    }

    deleteDetail(id): Observable<void> {
        return this.http.delete<any>(`${this.baseUrl}/accounts-receivable-detail/${id}`);
    }
}
