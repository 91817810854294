import {Injectable} from '@angular/core';
import {Page} from '../domain/page';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Installment} from '@app/domain/installment';

@Injectable({
    providedIn: 'root'
})
export class InstallmentService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    save(model: Installment): Observable<Installment> {
        return this.http.post<Installment>(`${this.baseUrl}/installments`, model);
    }

    update(id, model: Installment): Observable<Installment> {
        return this.http.put<Installment>(`${this.baseUrl}/installments/${id}`, model);
    }

    changeStatus(id, model: Installment): Observable<Installment> {
        return this.http.patch<Installment>(`${this.baseUrl}/installments/${id}/status`, model);
    }

    findAllPage(params: string): Observable<Page<Installment>> {
        return this.http.get<any>(`${this.baseUrl}/installments?${params}`);
    }

    findAllMe(): Observable<Installment[]> {
        return this.http.get<Installment[]>(`${this.baseUrl}/installments/me`);
    }

    findById(id: number): Observable<Installment> {
        return this.http.get<Installment>(`${this.baseUrl}/installments/${id}`);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/installments/${id}`);
    }
}
