import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {InventoryAdjustment} from '@app/domain/inventory-adjustment';
import {Page} from '@app/domain/page';
import {InventoryRecord} from '@app/domain/inventory-record';
import {TotalesInventario} from '@app/domain/totales-inventario';
import {Inventory} from '@app/domain/inventory';
import {TotalInventario} from "@app/domain/total-inventario";

@Injectable({
    providedIn: 'root'
})
export class InventoryService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllInventoryAdjustment(params): Observable<Page<InventoryAdjustment>> {
        return this.http.get<Page<InventoryAdjustment>>(`${this.baseUrl}/inventory-adjustment?${params}`);
    }

    saveInventoryAdjustment(model: InventoryAdjustment): Observable<InventoryAdjustment> {
        return this.http.post<InventoryAdjustment>(`${this.baseUrl}/inventory-adjustment`, model);
    }

    findAllProductsPage(params: string): Observable<Page<Inventory>> {
        return this.http.get<Page<Inventory>>(`${this.baseUrl}/inventory?${params}`);
    }

    findTotales(params: string): Observable<TotalesInventario[]> {
        return this.http.get<TotalesInventario[]>(`${this.baseUrl}/inventory/operations/totales?${params}`);
    }

    findTotalStock(params: string): Observable<TotalInventario> {
        return this.http.get<TotalInventario>(`${this.baseUrl}/inventory/operations/total-stock?${params}`);
    }

    findAllLogsInventory(idInventory, params): Observable<Page<InventoryRecord>> {
        return this.http.get<Page<InventoryRecord>>(`${this.baseUrl}/inventory/${idInventory}?${params}`);
    }

    initByOffice(conStock): Observable<TotalesInventario[]> {
        return this.http.post<TotalesInventario[]>(`${this.baseUrl}/inventory/operations/iniciarPorPuntoVenta`, conStock);
    }

    countProductEngaged(params: string): Observable<number> {
        return this.http.get<number>(`${this.baseUrl}/inventory/operations/product-engaged?${params}`);
    }

}
