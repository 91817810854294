import {Component, OnDestroy, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {environment} from '@env/environment';
import {Select, Store} from '@ngxs/store';
import {SetAppVersion} from '@app/state/sesion/sesion.actions';
import {Observable, Subject} from 'rxjs';
import {SesionState} from '@app/state/sesion/sesion.state';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

    private unsubscribe$ = new Subject<void>();
    @Select(SesionState.getAppVersion) appVersion$: Observable<string>;
    appVersion: string;

    horizontalMenu: boolean;

    darkMode = false;

    menuColorMode = 'light';

    menuColor = 'layout-menu-light';

    themeColor = 'blue';

    layoutColor = 'blue';

    ripple = true;

    inputStyle = 'outlined';

    constructor(private primengConfig: PrimeNGConfig, private store: Store) {}

    ngOnInit() {
        this.appVersion$.pipe(takeUntil(this.unsubscribe$)).subscribe(av => {
            this.appVersion = av;
        });
        this.primengConfig.ripple = true;
        this.getVersion();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getVersion() {
        fetch(`${environment.endpointApi}/public/version`)
            .then(response => response.json())
            .then(data => {
                if (!this.appVersion) {
                    this.store.dispatch(new SetAppVersion(data.version));
                } else {
                    if (data.version !== this.appVersion) {
                        this.store.dispatch(new SetAppVersion(data.version));
                        alert(`Nueva versión de la aplicación detectada, el navegador se actualizará de inmediato a la versión ${data.version}.`);
                        // @ts-ignore
                        location.reload(true);
                    }
                }
            });
    }

}
