import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '@app/domain/page';
import {Contingency} from '@app/domain/contingency';


@Injectable({
    providedIn: 'root'
})
export class ContingencyService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string): Observable<Page<Contingency>> {
        return this.http.get<Page<Contingency>>(`${this.baseUrl}/contingency?${params}`);
    }
}
