import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {catchError} from 'rxjs';
import {LoginService} from '../service/login.service';
import {MessageService} from 'primeng/api';
import {LoaderRequestService} from '../service/loader-request.service';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: LoginService, private messageService: MessageService,
                private loaderService: LoaderRequestService, private router: Router) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.auth.getToken()) {
            request = request.clone({
                setHeaders: {
                    Authorization: this.auth.getToken(),
                    companyInfo: this.auth.getCompanyInfo()
                }
            });
        }
        this.showLoader(request);

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.onEnd(request);
                }
                return event;
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                this.onEnd(request);
                if (errorResponse && errorResponse.error && errorResponse.error.mensaje) {
                    this.messageService.add({severity: 'error', summary: 'Error', detail: errorResponse.error.mensaje});
                } else {
                    if (errorResponse.error) {
                        if (errorResponse.error.message) {
                            this.messageService.add({severity: 'error', summary: 'Error', detail: errorResponse.error.message});
                        }
                        if (errorResponse.error.error === 'Unauthorized') {
                            this.router.navigateByUrl('/logout');
                        }
                    } else {
                        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Ocurrió un error'});
                    }
                }

                return throwError(errorResponse);

            }));

    }


    private onEnd(request: HttpRequest<any>): void {
        this.hideLoader(request);
    }

    private showLoader(request: HttpRequest<any>): void {
        const background = request.method === 'GET';
        const hideLoader = request.headers.get('hideLoader');
        const isPrinting = request.headers.get('isPrinting');
        if (!hideLoader) {
            this.loaderService.call(
                {
                    show: true,
                    background
                }
            );
        }
        if (isPrinting) {
            this.loaderService.call(
                {
                    show: true,
                    background: true
                }
            );
        }
    }

    private hideLoader(request: HttpRequest<any>): void {
        const background = request.method === 'GET';
        const hideLoader = request.headers.get('hideLoader');
        const isPrinting = request.headers.get('isPrinting');
        if (!hideLoader) {
            this.loaderService.call(
                {
                    show: false,
                    background
                }
            );
        }
        if (isPrinting) {
            this.loaderService.call(
                {
                    show: false,
                    background: true
                }
            );
        }
    }
}
