import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Employee} from '../domain/employee';

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string): Observable<Page<Employee>> {
        return this.findAllC(params) as Observable<Page<Employee>>;
    }

    findAllC(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/employees?${params}`);
    }

    findById(id: number): Observable<Employee> {
        return this.http.get<Employee>(`${this.baseUrl}/employees/${id}`);
    }

    save(model: Employee): Observable<Employee> {
        return this.http.post<Employee>(`${this.baseUrl}/employees`, model);
    }
    update(id, model: Employee): Observable<Employee> {
        return this.http.patch<Employee>(`${this.baseUrl}/employees/${id}`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/employees/${id}`);
    }

}
