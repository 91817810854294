import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {TicketFee} from '@app/domain/ventas/ticket-fee';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';
import {Printer} from '@app/domain/printer';
import {QzTrayService} from '@app/service/qz-tray.service';

@Injectable({
    providedIn: 'root'
})
export class TicketFeeService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient, private sanitizer: DomSanitizer,
                private qzEscPosService: QzEscPosService,
                private coffeEscPosService: CoffeEscPosService,
                private qzTrayService: QzTrayService) {
    }

    findAllPage(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/ticket-fee?${params}`);
    }

    openPdf(ticketFee: TicketFee, options: any): Observable<any> {
        const url = this.getUrlPdf(ticketFee);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    if (options.escPos) {
                        if (options.clienteImpresion === 'QZ') {
                            this.qzEscPosService.ticketFeeEscPos(ticketFee.id, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        } else {
                            this.coffeEscPosService.ticketFeeEscPos(ticketFee.id, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        }
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                });
            });
        });
    }

    getUrlPdf(ticketFee: TicketFee) {
        return `${environment.endpointApi}/public/pdf/ticket-fee/${ticketFee.id}`;
    }

    printInQz(data, printer: Printer, escPos: boolean) {
        const options = {
            flavor: 'base64',
            escPos
        };
        this.qzTrayService.queue.next({printer, data, options});
    }

}
