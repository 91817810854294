import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Group} from '@app/domain/group';


@Injectable({
    providedIn: 'root'
})
export class GroupService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<Group[]> {
        return this.http.get<Group[]>(`${this.baseUrl}/groups?${params}`);
    }

    findById(id): Observable<Group> {
        return this.http.get<Group>(`${this.baseUrl}/groups/${id}`);
    }

    save(model: Group): Observable<Group> {
        return this.http.post<Group>(`${this.baseUrl}/groups`, model);
    }

    update(id, model: Group): Observable<Group> {
        return this.http.put<Group>(`${this.baseUrl}/groups/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/groups/${id}`);
    }
}
