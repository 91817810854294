
export enum EstadoOrdenRestaurante {
    PENDIENTE = 'PENDIENTE',
    PRECUENTA = 'PRECUENTA',
    CANCELADO = 'CANCELADO',
    FINALIZADO = 'FINALIZADO'
}

export enum EstadoDetalleOrdenRestaurante {
    NO_ENVIADO = 'NENVIADO',
    PENDIENTE = 'PENDIENTE',
    PREPARACION = 'PREPARACION',
    CANCELADO = 'CANCELADO',
    DESPACHADO = 'DESPACHADO'
}
