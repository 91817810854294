import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Bank} from '../domain/bank';

@Injectable({
  providedIn: 'root'
})
export class BankService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(idEmpresa, params: string): Observable<Page<Bank>> {
        return this.http.get<Page<Bank>>(`${this.baseUrl}/banks?${params}`);
    }

    findAll(idEmpresa): Observable<Bank[]> {
        return this.http.get<Bank[]>(`${this.baseUrl}/banks/all`);
    }

    findById(idEmpresa, id: number): Observable<Bank> {
        return this.http.get<Bank>(`${this.baseUrl}/banks/${id}`);
    }

    save(idEmpresa, model: Bank): Observable<Bank> {
        return this.http.post<Bank>(`${this.baseUrl}/banks`, model);
    }

    update(idEmpresa, model: Bank): Observable<Bank> {
        return this.http.put<Bank>(`${this.baseUrl}/banks`, model);
    }

    deleteById(idEmpresa, id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/banks/${id}`);
    }
}
