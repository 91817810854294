import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Category} from '../domain/category';
import {map} from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class CategoryService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/categories?${params}`);
    }

    findAllPage(params: string): Observable<Page<Category>> {
        return this.findAll(params).pipe(map(data => data as Page<Category>));
    }

    findAllList(params: string): Observable<Category[]> {
        return this.findAll(params).pipe(map(data => data as Category[]));
    }

    findById(id: number): Observable<Category> {
        return this.http.get<Category>(`${this.baseUrl}/categories/${id}`);
    }

    save(idEmpresa, model: Category): Observable<Category> {
        return this.http.post<Category>(`${this.baseUrl}/categories`, model);
    }

    update(idEmpresa, model: Category): Observable<Category> {
        return this.http.put<Category>(`${this.baseUrl}/categories`, model);
    }

    deleteById(idEmpresa, id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/categories/${id}`);
    }
}
