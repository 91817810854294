import {Component, OnInit} from '@angular/core';
import {LoginService} from '@app/service/login.service';

@Component({
    selector: 'app-logout',
    template: `
        <div>Cerrando sesión</div>
    `
})
export class AppLogoutComponent implements OnInit {

    ngOnInit(): void {
        setTimeout(() => {
            this.loginService.logOut();
        }, 0);
    }

    constructor(public loginService: LoginService) {
    }

}
