import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {BankAccount} from '../domain/bank-account';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(idEmpresa, params: string): Observable<Page<BankAccount>> {
        return this.http.get<Page<BankAccount>>(`${this.baseUrl}/bank-accounts?${params}`);
    }

    findAll(idEmpresa): Observable<BankAccount[]> {
        return this.http.get<BankAccount[]>(`${this.baseUrl}/bank-accounts/all`);
    }

    findById(idEmpresa, id: number): Observable<BankAccount> {
        return this.http.get<BankAccount>(`${this.baseUrl}/bank-accounts/${id}`);
    }

    save(idEmpresa, model: BankAccount): Observable<BankAccount> {
        return this.http.post<BankAccount>(`${this.baseUrl}/bank-accounts`, model);
    }

    update(idEmpresa, model: BankAccount): Observable<BankAccount> {
        return this.http.put<BankAccount>(`${this.baseUrl}/bank-accounts`, model);
    }

    deleteById(idEmpresa, id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/bank-accounts/${id}`);
    }
}
