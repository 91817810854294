import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tipoDocumento'
})
export class TipoDocumentoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value === '01') {
            return 'Factura';
        }
        if (value === '03') {
            return 'Boleta';
        }
        if (value === 'NV') {
            return 'Nota de venta';
        }
        if (value === '07') {
            return 'Nota de crédito';
        }
        if (value === '08') {
            return 'Nota de débito';
        }
        if (value === '09') {
            return 'Guía de remisión electrónica';
        }
        if (value === 'GM') {
            return 'Guía de remisión manual';
        }
        if (value === 'CE') {
            return 'Constancia de entrega';
        }
        if (value === 'CO') {
            return 'Cotización';
        }
        return '';
    }

}
