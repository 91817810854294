import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Brand} from '../domain/brand';
import {environment} from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class BrandService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/brands?${params}`);
    }

    findById(idEmpresa, id: number): Observable<Brand> {
        return this.http.get<Brand>(`${this.baseUrl}/brands/${id}`);
    }

    save(idEmpresa, model: Brand): Observable<Brand> {
        return this.http.post<Brand>(`${this.baseUrl}/brands`, model);
    }

    update(idEmpresa, model: Brand): Observable<Brand> {
        return this.http.put<Brand>(`${this.baseUrl}/brands`, model);
    }

    deleteById(idEmpresa, id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/brands/${id}`);
    }
}
