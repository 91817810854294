<span tooltipPosition="bottom" pTooltip="Versión {{appVersion$ | async}}" class="m-2">
    <p-tag value="V. {{appVersion$ | async}}" severity="info"></p-tag>
</span>
<p-dropdown [disabled]="!canSelectCompany" dataKey="id" [(ngModel)]="companySelected" (onChange)="selectCompanyFromEvent($event)"
            [style]="{'width':'200px'}" [options]="attachedCompanies"
            optionLabel="razonSocial" name="company">
    <ng-template let-item pTemplate="selectedItem">
        <span style="vertical-align:middle;"><span class="fa fa-home" aria-hidden="true"></span></span>
        <span style="vertical-align:middle; margin-left: .5em">{{item.razonSocial}}</span>
    </ng-template>
</p-dropdown>


