import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {ProductPlanField} from '@app/domain/product-plan-field';


@Injectable({
    providedIn: 'root'
})
export class ProductPlanFieldService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<ProductPlanField[]> {
        return this.http.get<ProductPlanField[]>(`${this.baseUrl}/product-plan-fields?${params}`);
    }

    findById(id): Observable<ProductPlanField> {
        return this.http.get<ProductPlanField>(`${this.baseUrl}/product-plan-fields/${id}`);
    }

    save(model: ProductPlanField): Observable<ProductPlanField> {
        return this.http.post<ProductPlanField>(`${this.baseUrl}/product-plan-fields`, model);
    }

    update(id, model: ProductPlanField): Observable<ProductPlanField> {
        return this.http.put<ProductPlanField>(`${this.baseUrl}/product-plan-fields/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/product-plan-fields/${id}`);
    }
}
