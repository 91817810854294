import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {LoginService} from '../service/login.service';


@Injectable()
export class AuthGuardService  {

  constructor(public loginService: LoginService, public router: Router) {
  }

  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (!this.loginService.getToken() && state.url !== '/login') {
      this.router.navigateByUrl('/login');
      return false;
    } else if (this.loginService.getToken() && state.url === '/login') {
      this.router.navigateByUrl('/');
      return false;
    }
    return true;
  }

}
