import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Supplier} from '../domain/supplier';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/suppliers?${params}`);
    }

    findAllList(params: string): Observable<Supplier[]> {
        return this.http.get<Supplier[]>(`${this.baseUrl}/suppliers?${params}`);
    }

    findAllPage(params: string): Observable<Page<Supplier>> {
        return this.findAll(params).pipe(map(data => data as Page<Supplier>));
    }

    findById(id: number): Observable<Supplier> {
        return this.http.get<Supplier>(`${this.baseUrl}/suppliers/${id}`);
    }

    save(model: Supplier): Observable<Supplier> {
        return this.http.post<Supplier>(`${this.baseUrl}/suppliers`, model);
    }

    update(model: Supplier): Observable<Supplier> {
        return this.http.put<Supplier>(`${this.baseUrl}/suppliers`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/suppliers/${id}`);
    }
}
