import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {EmpresaConsultada} from '../domain/ventas/empresa-consultada';
import {City} from '@app/domain/city';
import {map} from 'rxjs/operators';
import {ConsultaDocument} from '@app/domain/ventas/consulta-document';

@Injectable({
    providedIn: 'root'
})
export class ConsultaDocumentosService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findDocument(doc, natural): Observable<ConsultaDocument> {
        const  options = {
            headers: {hideLoader: 'S'}
        };
        return this.http.get<ConsultaDocument>(`${this.baseUrl}/search-customer-document?doc=${doc}&natural=${natural}`, options);
    }

    findCity(filter): Observable<City[]> {
        return this.http.get<City[]>(`${this.baseUrl}/city?filter=${filter}`)
            .pipe(map(data => data.map(c => ({...c, descripcion: `${c.codigoCiudad} - ${c.ciudad}`}))));
    }

}
