<div class="layout-wrapper" (click)="onLayoutClick()"
     [ngClass]="{'layout-horizontal': app.horizontalMenu,
                'layout-wrapper-static': staticMenuActive,
                'layout-rightpanel-active': rightPanelActive,
                'layout-topbar-mobile-active': topbarMobileMenuActive,
                'layout-megamenu-mobile-active': megaMenuMobileActive,
                'layout-sidebar-mobile-active': menuMobileActive,
                'p-input-filled': app.inputStyle === 'filled',
                'p-ripple-disabled': !app.ripple}" [class]="app.menuColor">




    <app-menu [roles]="roles"></app-menu>

    <div class="layout-main">
        <app-topbar [roles]="roles"></app-topbar>

        <app-rightpanel></app-rightpanel>

        <app-breadcrumb></app-breadcrumb>

        <div *ngIf="installmentsPending.length" class="p-fluid">
            <p-messages severity="error">
                <ng-template pTemplate>
                    <img src="assets/resources/images/late-payment.png" width="32" />
                    <div class="grid grid-nogutter ml-2">
                        <div class="col-12 text-bold">Pagos atrasados</div>
                        <div class="col-12">
                            <p>
                                Tiene {{installmentsPending.length}} pago(s) atrasado(s). Para evitar la suspensión o la terminación de su cuenta de {{nameCompany}}, pague el importe completo lo antes posible. Si ha realizado un pago recientemente, espere hasta 5 a 7 días hábiles para que los
                                pagos aparezcan en la página de <a routerLink="/pagos">Pagos</a> antes de ponerse en contacto con el servicio de atención al cliente.
                            </p>
                        </div>
                    </div>
                </ng-template>
            </p-messages>
        </div>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>

       <!-- <app-config></app-config>-->

        <div class="layout-main-mask"></div>

        <div class="layout-config-mask" *ngIf="configActive" [@mask-anim]="'visible'"></div>
    </div>
</div>
