import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoginService} from '@app/service/login.service';

@Directive({
    selector: '[appPermission]'
})
export class PermissionDirective {

    constructor(
        private element: ElementRef,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private loginService: LoginService
    ) {
    }


    @Input()
    set appPermission(val) {
      if (this.loginService.hasPermission(val)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
