import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

import { io } from 'socket.io-client';
import {LoginService} from '@app/service/login.service';
import {EventEnum} from '@app/enums/event.enum';
import {Office} from '@app/domain/office';
import {PrintPdf} from '@app/domain/print-pdf';
import {PrintEscPos} from '@app/domain/print-esc-pos';
import {Socket} from 'socket.io-client/build/esm/socket';

@Injectable({
    providedIn: 'root'
})
export class SocketIoService {

    private socket: Socket;

    constructor(private loginService: LoginService) {
    }

    get connected(): boolean {
        return this.socket != null && this.socket.connected;
    }

    public initSocket(pv: Office): void {
        if (pv) {
            const params = {
                auth_token: this.loginService.getToken().replace('Bearer ', ''),
                officeCode: `${pv.codigo ? pv.codigo : 'PV001'}`,
                clientType: 'WEB'
            };
            // @ts-ignore
            this.socket = io(environment.wsEndpoint,
                {
                    secure: true,
                    query: params,
                    path: '/tekiwss',
                    timeout: 4000,
                    ackTimeout: 3000
                });
        }
    }

    public printCoffePdf(printPdf: PrintPdf): void {
        this.socket.emit('printPdf', printPdf);
    }

    public printCoffeEscPos(printEscPos: PrintEscPos): void {
        this.socket.emit('printEscPos', printEscPos);
    }

    public onTicket(): Observable<{}> {
        return new Observable<{}>(observer => {
            this.socket.on('ticket', (data, callback) => {
                observer.next(data);
                callback(true);
            });
        });
    }

    public onCallAutomatic(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.socket.on('cambioLlamadoAutomatico', (data) => observer.next(data));
        });
    }

    public onCloseReason(): Observable<string> {
        return new Observable<string>(observer => {
            this.socket.on('closeReason', (data) => observer.next(data));
        });
    }

    public onEvent(event: EventEnum): Observable<any> {
        return new Observable<EventEnum>(observer => {
            this.socket.on(event, (data) => observer.next(data));
        });
    }

    public disconnect(): void {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
