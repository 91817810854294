import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {BatchProduct} from '@app/domain/batch-product';
import {Page} from "@app/domain/page";



@Injectable({
    providedIn: 'root'
})
export class BatchProductService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<BatchProduct[]> {
        return this.http.get<BatchProduct[]>(`${this.baseUrl}/batch-products?${params}`);
    }

    findAllPage(params): Observable<Page<BatchProduct>> {
        return this.http.get<Page<BatchProduct>>(`${this.baseUrl}/batch-products?${params}`);
    }

    findById(id): Observable<BatchProduct> {
        return this.http.get<BatchProduct>(`${this.baseUrl}/batch-products/${id}`);
    }

    save(model: BatchProduct): Observable<BatchProduct> {
        return this.http.post<BatchProduct>(`${this.baseUrl}/batch-products`, model);
    }

    update(id, model: BatchProduct): Observable<BatchProduct> {
        return this.http.put<BatchProduct>(`${this.baseUrl}/batch-products/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/batch-products/${id}`);
    }
}
