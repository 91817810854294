import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {ContractPlan} from '@app/domain/contract-plan';

@Injectable({
    providedIn: 'root'
})
export class ContractPlanService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<ContractPlan[]> {
        return this.http.get<ContractPlan[]>(`${this.baseUrl}/plan-contracts?${params}`);
    }

    findById(id): Observable<ContractPlan> {
        return this.http.get<ContractPlan>(`${this.baseUrl}/plan-contracts/${id}`);
    }

    save(model: ContractPlan): Observable<ContractPlan> {
        return this.http.post<ContractPlan>(`${this.baseUrl}/plan-contracts`, model);
    }

    update(id, model: ContractPlan): Observable<ContractPlan> {
        return this.http.put<ContractPlan>(`${this.baseUrl}/plan-contracts/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/plan-contracts/${id}`);
    }
}
