import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {SaleStation} from '@app/domain/sale-station';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SaleStationService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/sale-stations?${params}`);
    }

    findAllPage(params: string): Observable<Page<SaleStation>> {
        return this.findAll(params).pipe(map(data => data as Page<SaleStation>));
    }

    findAllList(params: string): Observable<SaleStation[]> {
        return this.findAll(params).pipe(map(data => data as SaleStation[]));
    }

    findById(id: number): Observable<SaleStation> {
        return this.http.get<SaleStation>(`${this.baseUrl}/sale-stations/${id}`);
    }

    save(model: SaleStation): Observable<SaleStation> {
        return this.http.post<SaleStation>(`${this.baseUrl}/sale-stations`, model);
    }

    update(model: SaleStation): Observable<SaleStation> {
        return this.http.put<SaleStation>(`${this.baseUrl}/sale-stations`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/sale-stations/${id}`);
    }
}
