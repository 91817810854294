import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {City} from '@app/domain/city';
import {Ubigeo} from '@app/domain/ubigeo';


@Injectable({
    providedIn: 'root'
})
export class CityService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllUbigeo(params): Observable<Ubigeo[]> {
        return this.http.get<Ubigeo[]>(`${this.baseUrl}/ubigeo?${params}`);
    }

    findAllUbigeoCobertura(): Observable<Ubigeo[]> {
        return this.http.get<Ubigeo[]>(`${this.baseUrl}/cities?ubigeo=true`);
    }

    findAll(): Observable<City[]> {
        return this.http.get<City[]>(`${this.baseUrl}/cities`);
    }

    save(model: City): Observable<City> {
        return this.http.post<City>(`${this.baseUrl}/cities`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/cities/${id}`);
    }
}
