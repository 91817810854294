import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {ImportFile} from '@app/domain/import-file';

@Injectable({
    providedIn: 'root'
})
export class ImportFileService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string, hideLoader: boolean): Observable<Page<ImportFile>> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get<Page<ImportFile>>(`${this.baseUrl}/import-file?${params}`, options);
    }

    cancel(id): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/import-file/${id}`);
    }
}
