import {Check} from '@app/domain/check';

export class SetFiltroTipoCuentaPorCobrar {
    static readonly type = '[CobradorRestaurant] SetFiltroTipoCuentaPorCobrar';

    constructor(public filtroTipoCuentaPorCobrar: any) {
    }
}

export class SetFiltroEstadoPagoCuentaPorCobrar {
    static readonly type = '[CobradorRestaurant] SetFiltroEstadoPagoCuentaPorCobrar';
    constructor(public filtroEstadoPagoCuentaPorCobrar: any) {
    }
}


export class SetFiltroFechaCuentaPorCobrar {
    static readonly type = '[CobradorRestaurant] SetFiltroFechaCuentaPorCobrar';
    constructor(public filtroFechaCuentaPorCobrar: any) {
    }
}


export class SetCuentaPorCobrar {
    static readonly type = '[CobradorRestaurant] SetCuentaPorCobrar';

    constructor(public cuentaPorCobrar: Check) {
    }
}

export class AddIdPedido {
    static readonly type = '[CobradorRestaurant] AddIdPedido';

    constructor(public id: number) {
    }
}


export class RemoveIdPedido {
    static readonly type = '[CobradorRestaurant] RemoveIdPedido';

    constructor(public id: number) {
    }
}

export class ClearIdPedido {
    static readonly type = '[CobradorRestaurant] ClearIdPedido';

    constructor() {
    }
}



