import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
    AddIdPedido,
    ClearIdPedido,
    RemoveIdPedido,
    SetCuentaPorCobrar,
    SetFiltroEstadoPagoCuentaPorCobrar,
    SetFiltroFechaCuentaPorCobrar,
    SetFiltroTipoCuentaPorCobrar
} from '@app/state/restaurant/cobrador/cobrador-restaurante.actions';
import {Check} from '@app/domain/check';
import {append, patch, removeItem} from '@ngxs/store/operators';
import {Injectable} from "@angular/core";

export class CobradorRestaurantStateModel {
    filtroTipoCuentaPorCobrar: any[];
    filtroEstadoPagoCuentaPorCobrar: boolean;
    filtroFechaCuentaPorCobrar: any;
    filtroIdPedido: any[];
    cuentaPorCobrar: Check;
}

@Injectable()
@State<CobradorRestaurantStateModel>({
    name: 'restaurant_cobrador',
    defaults: {
        filtroTipoCuentaPorCobrar: ['LOCAL', 'PEDIDO_LOCAL', 'PEDIDO_FORANEO', 'PEDIDO_ONLINE'],
        filtroEstadoPagoCuentaPorCobrar: false,
        filtroFechaCuentaPorCobrar: undefined,
        filtroIdPedido: [],
        cuentaPorCobrar: undefined
    }
})
export class CobradorRestauranteState {

    constructor() {
    }

    @Selector()
    static filtroTipoCuentaPorCobrar(state: CobradorRestaurantStateModel) {
        return state.filtroTipoCuentaPorCobrar;
    }

    @Selector()
    static filtroEstadoPagoCuentaPorCobrar(state: CobradorRestaurantStateModel) {
        return state.filtroEstadoPagoCuentaPorCobrar;
    }

    @Selector()
    static filtroFechaCuentaPorCobrar(state: CobradorRestaurantStateModel) {
        return state.filtroFechaCuentaPorCobrar;
    }

    @Selector()
    static filtroIdPedido(state: CobradorRestaurantStateModel) {
        return state.filtroIdPedido;
    }

    @Selector()
    static cuentaPorCobrar(state: CobradorRestaurantStateModel) {
        return state.cuentaPorCobrar;
    }

    @Action(SetFiltroTipoCuentaPorCobrar)
    setFiltroEstadoPagoCuentaPorCobrar({getState, setState}: StateContext<CobradorRestaurantStateModel>,
                                       {filtroTipoCuentaPorCobrar}: SetFiltroTipoCuentaPorCobrar) {
        const state = getState();
        setState({
            ...state,
            filtroTipoCuentaPorCobrar,
        });
    }

    @Action(SetFiltroEstadoPagoCuentaPorCobrar)
    setFiltroTipoCuentaPorCobrar({getState, setState}: StateContext<CobradorRestaurantStateModel>,
                                 {filtroEstadoPagoCuentaPorCobrar}: SetFiltroEstadoPagoCuentaPorCobrar) {
        const state = getState();
        setState({
            ...state,
            filtroEstadoPagoCuentaPorCobrar,
        });
    }

    @Action(SetFiltroFechaCuentaPorCobrar)
    setFiltroFechaCuentaPorCobrar({getState, setState}: StateContext<CobradorRestaurantStateModel>, {filtroFechaCuentaPorCobrar}: SetFiltroFechaCuentaPorCobrar) {
        const state = getState();
        setState({
            ...state,
            filtroFechaCuentaPorCobrar,
        });
    }

    @Action(SetCuentaPorCobrar)
    setCuentaPorCobrar({getState, setState}: StateContext<CobradorRestaurantStateModel>,
                       {cuentaPorCobrar}: SetCuentaPorCobrar) {
        const state = getState();
        setState({
            ...state,
            cuentaPorCobrar,
        });
    }


    @Action(AddIdPedido)
    addOrdenEnvio({getState, setState}: StateContext<CobradorRestaurantStateModel>, {id}:
        AddIdPedido) {
        const state = getState();
        setState(patch({
            filtroIdPedido: append([id])
        }));
    }

    @Action(RemoveIdPedido)
    removeOrdenEnvio({getState, setState}: StateContext<CobradorRestaurantStateModel>, {id}:
        RemoveIdPedido) {
        const state = getState();
        setState(patch({
            filtroIdPedido: removeItem<number>(order => order === id)
            })
        );
    }

    @Action(ClearIdPedido)
    clearOrdenesEnvio({getState, setState}: StateContext<CobradorRestaurantStateModel>) {
        const state = getState();
        setState({
            ...state,
            filtroIdPedido: [],
        });
    }
}
