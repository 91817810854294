import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {AttendanceRecord} from '@app/domain/attendance-record';

@Injectable({
    providedIn: 'root'
})
export class AttendanceRecordService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string, hideLoader?): Observable<Page<AttendanceRecord>> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get<any>(`${this.baseUrl}/attendance-record?${params}`, options);
    }

    save(model: AttendanceRecord): Observable<AttendanceRecord> {
        return this.http.post<AttendanceRecord>(`${this.baseUrl}/attendance-record`, model);
    }

    update(id, model: AttendanceRecord): Observable<AttendanceRecord> {
        return this.http.patch<AttendanceRecord>(`${this.baseUrl}/attendance-record/${id}`, model);
    }

    saveMassive(params: string, model: AttendanceRecord): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/attendance-record/massive?${params}`, model);
    }
    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/attendance-record/${id}`);
    }

}
