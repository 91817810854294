import {Injectable} from '@angular/core';
import {LoaderState} from '../domain/loader-state';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderRequestService {

  constructor() { }

    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();

    call(loaderState: LoaderState) {
        this.loaderSubject.next(loaderState);
    }
}

