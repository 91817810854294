import {Injectable} from '@angular/core';
import {Page} from '../domain/page';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Office} from '../domain/office';
import {getBaseUrl} from './util';

@Injectable({
    providedIn: 'root'
})
export class OfficeService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params, idEmpresa, modeRoot: boolean): Observable<any> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<any>(`${baseUrl}/offices?${params}`);
    }

    findAllByPage(idEmpresa, params: string, modeRoot: boolean): Observable<Page<Office>> {
        return this.findAll(params, idEmpresa, modeRoot);
    }

    findById(idEmpresa, id: number, modeRoot: boolean): Observable<Office> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<Office>(`${baseUrl}/offices/${id}`);
    }

    save(idEmpresa, model: Office, modeRoot: boolean): Observable<Office> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.post<Office>(`${baseUrl}/offices`, model);
    }

    update(idEmpresa, id, model: Office, modeRoot: boolean): Observable<Office> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.put<Office>(`${baseUrl}/offices/${id}`, model);
    }

    deleteById(idEmpresa, id: number, modeRoot: boolean): Observable<void> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.delete<void>(`${baseUrl}/offices/${id}`);
    }

}
