

export class SetFiltrosTipoPedido {
    static readonly type = '[PedidosRestaurant] SetFiltrosTipoPedido';

    constructor(public filtroTiposPedidos: any[]) {
    }
}

export class SetFiltroEstadoDelivery {
    static readonly type = '[PedidosRestaurant] SetFiltroEstadoDelivery';

    constructor(public filtroEstadoDelivery: any[]) {
    }
}


export class SetBusquedaPedidos {
    static readonly type = '[PedidosRestaurant] SetBusquedaPedidos';
    constructor(public busquedaPedidos: any) {
    }
}


