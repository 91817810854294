import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SetFiltros} from '@app/state/restaurant/cocina/cocina-restaurante.actions';
import {Injectable} from "@angular/core";

export class CocinaRestaurantStateModel {
    filtros: any;
}

@Injectable()
@State<CocinaRestaurantStateModel>({
    name: 'restaurant_cocina',
    defaults: {
        filtros: {
            paraLlevar: 'all',
            modoCocina: true,
            modoBarra: true,
            estado: 'PENDIENTE',
            apartado: 'C',
            desde: new Date().getTime(),
            hasta: new Date().getTime(),
            tiempoPreparacion: 15,
            areasProduccionSelect: []
        }
    }
})
export class CocinaRestauranteState {

    constructor() {
    }

    @Selector()
    static filtros(state: CocinaRestaurantStateModel) {
        return state.filtros;
    }

    @Action(SetFiltros)
    setFiltroTipoCuentaPorCobrar({getState, setState}: StateContext<CocinaRestaurantStateModel>,
                                 {filtros}: SetFiltros) {
        const state = getState();
        setState({
            ...state,
            filtros,
        });
    }

}
