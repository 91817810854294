import {MenuItem} from 'primeng/api';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {SetItems} from '@app/state/breadcrumb/breadcrumb.actions';
import {Injectable} from "@angular/core";


export class BreadcrumbStateModel {
    items: MenuItem[];
}

@Injectable()
@State<BreadcrumbStateModel>({
    name: 'breadcrumb',
    defaults: {
        items: []
    }
})
export class BreadcrumbState {

    constructor() {
    }

    @Selector()
    static getItems(state: BreadcrumbStateModel) {
        return state.items;
    }

    @Action(SetItems)
    setItems({getState, setState}: StateContext<BreadcrumbStateModel>, {items}: SetItems) {
        const state = getState();
        setState({
            ...state,
            items
        });
    }

}
