export const environment = {
    production: true,
    endpointApi: 'https://api.teki.pe/api',
    urlLogo: 'assets/layout/images/logo-mirage@2x.png',
    wsEndpoint: 'https://sock.teki.pe',
    wsEndpointPrint: 'https://sock.teki.pe',
    balanzaEndpoint: 'http://127.0.0.1:9292',
    reconnectInterval: 2000,
    company: 'Teki'
};
