import {Injectable} from '@angular/core';

import {Page} from '../domain/page';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TicketDesign} from '../domain/ticket-design';

@Injectable({
  providedIn: 'root'
})
export class TicketDesignService {

    baseUrl: string = environment.endpointApi;
    constructor(private http: HttpClient) {
    }

    findAllPage(params: string): Observable<Page<TicketDesign>> {
        return this.http.get<Page<TicketDesign>>(`${this.baseUrl}/root/ticketDesigns?${params}`);
    }

    findAll(): Observable<TicketDesign[]> {
        return this.http.get<TicketDesign[]>(`${this.baseUrl}/root/ticketDesigns/all`);
    }


    findAllByIdEmpresa(id): Observable<TicketDesign[]> {
        return this.http.get<TicketDesign[]>(`${this.baseUrl}/root/ticketDesigns/all/${id}`);
    }

    findById(id: number): Observable<TicketDesign> {
        return this.http.get<TicketDesign>(`${this.baseUrl}/root/ticketDesigns/${id}`);
    }

    save(model: TicketDesign): Observable<TicketDesign> {
        return this.http.post<TicketDesign>(`${this.baseUrl}/root/ticketDesigns`, model);
    }

    update(model: TicketDesign): Observable<TicketDesign> {
        return this.http.put<TicketDesign>(`${this.baseUrl}/root/ticketDesigns`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/root/ticketDesigns/${id}`);
    }

    upload(formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/files/ticketDesigns/upload`, formData);
    }
}
