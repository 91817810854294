import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SesionState} from '@app/state/sesion/sesion.state';
import {Observable, Subject} from 'rxjs';
import {Company} from '@app/domain/company';
import {takeUntil} from 'rxjs/operators';
import {SetCompanySelected, SetOffice} from '@app/state/sesion/sesion.actions';
import {LoginService} from '@app/service/login.service';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  @Select(SesionState.getCompanySelected) companySelected$: Observable<Company>;
  companySelected: Company;

  @Select(SesionState.getCompany) company$: Observable<Company>;
  company: Company;

  @Select(SesionState.getAppVersion) appVersion$: Observable<string>;

  attachedCompanies: Company[] = [];

  displayCompanies = false;

  constructor(private loginService: LoginService, private store: Store) { }

  ngOnInit(): void {
    this.companySelected$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ev => {
          this.companySelected = ev;
        });
    this.company$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(ev => {
          this.company = ev;
        });
    this.loadAttachedCompanies();
  }

  get canSelectCompany() {
    return this.loginService.hasPermission('SELECCIONAR_EMPRESAS_ADJUNTAS');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadAttachedCompanies() {
    this.attachedCompanies = [this.company, ...this.loginService.getUser().empresasAdjuntas];
  }

  openDialog(e: Event) {
    e.preventDefault();
    if (this.attachedCompanies.length > 1) {
      this.displayCompanies = true;
    }
  }

  selectCompanyFromEvent(event: any) {
    this.selectCompany(event.value);
  }

  selectCompany(company: Company) {
    this.store.dispatch(new SetCompanySelected(company));
    this.displayCompanies = false;
    const puntoVenta = this.loginService.getLogin().user.puntosVenta.find(pv => pv.rucAsignado === company.ruc);
    if (puntoVenta) {
        this.store.dispatch(new SetOffice({office: puntoVenta}));
    }
  }

}
