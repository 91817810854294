import {Injectable} from '@angular/core';
import {FileStorage} from '../domain/file-storage';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    downloadFile(model: FileStorage) {
        this.http.post(`${this.baseUrl}/files/storage/download`, model, {responseType: 'blob'}).subscribe(data => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const blob = new Blob([data], {type: 'octet/stream'});
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = model.nombreOriginal;
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }
}
