
export class SetFiltros {
    static readonly type = '[CocinaRestaurant] SetFiltros';

    constructor(public filtros: any) {
    }
}



