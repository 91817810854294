import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Preparation} from '@app/domain/preparation';


@Injectable({
    providedIn: 'root'
})
export class PreparationService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<Preparation[]> {
        return this.http.get<Preparation[]>(`${this.baseUrl}/preparations?${params}`);
    }

    findById(id): Observable<Preparation> {
        return this.http.get<Preparation>(`${this.baseUrl}/preparations/${id}`);
    }

    save(model: Preparation): Observable<Preparation> {
        return this.http.post<Preparation>(`${this.baseUrl}/preparations`, model);
    }

    update(id, model: Preparation): Observable<Preparation> {
        return this.http.put<Preparation>(`${this.baseUrl}/preparations/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/preparations/${id}`);
    }
}
