import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileUploadModule} from 'primeng/fileupload';
import {SpinnerModule} from 'primeng/spinner';
import {PickListModule} from 'primeng/picklist';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {HttpClientModule} from '@angular/common/http';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {PaginatorModule} from 'primeng/paginator';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ProgressBarModule} from 'primeng/progressbar';
import {DropdownModule} from 'primeng/dropdown';
import {TabViewModule} from 'primeng/tabview';
import {SliderModule} from 'primeng/slider';
import {MessageModule} from 'primeng/message';
import {SelectButtonModule} from 'primeng/selectbutton';
import {TreeModule} from 'primeng/tree';
import {MenubarModule} from 'primeng/menubar';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {MenuModule} from 'primeng/menu';
import {TooltipModule} from 'primeng/tooltip';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TabMenuModule} from 'primeng/tabmenu';
import {CalendarModule} from 'primeng/calendar';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastModule} from 'primeng/toast';
import {FieldsetModule} from 'primeng/fieldset';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {CheckboxModule} from 'primeng/checkbox';
import {PanelMenuModule} from 'primeng/panelmenu';
import {DialogModule} from 'primeng/dialog';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TableModule} from 'primeng/table';
import {TreeTableModule} from 'primeng/treetable';
import {InputTextModule} from 'primeng/inputtext';
import {RadioButtonModule} from 'primeng/radiobutton';
import {AccordionModule} from 'primeng/accordion';
import {PanelModule} from 'primeng/panel';
import {TipoDocumentoPipe} from '../pipe/tipo-documento.pipe';
import {MonedaPipe} from '../pipe/moneda.pipe';
import {EstadoComprobantePipe} from '../pipe/estado-comprobante.pipe';
import {TipoDocumentoIdentidadPipe} from '../pipe/tipo-documento-identidad.pipe';
import {MontoDineroPipe} from '../pipe/monto-dinero.pipe';
import {EstadoSunatPipe} from '../pipe/estado-sunat.pipe';
import {EstadoOrdenRestaurantePipe} from '@app/pipe/estado-orden-restaurante.pipe';
import {EstadoDetalleOrdenRestaurantePipe} from '@app/pipe/estado-detalle-orden-restaurante.pipe';
import {MotivoTrasladoPipe} from '@app/pipe/motivo-traslado.pipe';
import {TipoTransportePipe} from '@app/pipe/tipo-transporte.pipe';
import {EstadoSummaryPipe} from '@app/pipe/estado-summary.pipe';
import {DateAgoPipe} from '@app/pipe/date-ago.pipe';
import {TipoGuiaPipe} from '@app/pipe/tipo-guia.pipe';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {ActionsComponent} from '@app/components/shared/actions/actions.component';
import {ReadonlyDirective} from '@app/directives/readonly.directive';
import {TipoPrecioPipe} from '@app/pipe/tipo-precio.pipe';
import {EstadoTrasladoInventarioPipe} from '@app/pipe/estado-traslado-inventario.pipe';
import {TipoProductoPipe} from '@app/pipe/tipo-producto.pipe';
import {GoogleAutocompletePlaceComponent} from '@app/components/shared/google-autocomplete-place/google-autocomplete-place.component';
import {CompletarCerosPipe} from '@app/pipe/completar-ceros.pipe';
import {TipoPedidoPipe} from '@app/pipe/tipo-pedido.pipe';
import {EstadoPedidoOnlinePipe} from '@app/pipe/estado-pedido-online.pipe';
import {EstadoPedidoRestaurantePipe} from '@app/pipe/estado-pedido-restaurante.pipe';
import {PermissionDirective} from '@app/directives/permission.directive';
import {SidebarModule} from 'primeng/sidebar';
import {KeyFilterModule} from 'primeng/keyfilter';
import {MultiSelectModule} from 'primeng/multiselect';
import {ChartModule} from 'primeng/chart';
import {ConceptoMovimientoPipe} from '@app/pipe/concepto-movimiento.pipe';
import {DragAndDropModule} from 'angular-draggable-droppable';
import {FullCalendarModule} from '@fullcalendar/angular';
import {DividerModule} from 'primeng/divider';
import {TagModule} from 'primeng/tag';
import {NgxsFormPluginModule} from '@ngxs/form-plugin';
import {EstadoMembresiaPipe} from '@app/pipe/estado-membresia.pipe';
import {RippleModule} from 'primeng/ripple';
import {EstadoEmpleadoPipe} from '@app/pipe/estado-empleado.pipe';
import {AutoFocusModule} from "primeng/autofocus";


@NgModule({
    declarations: [
        EstadoComprobantePipe,
        TipoDocumentoPipe,
        TipoDocumentoIdentidadPipe,
        MontoDineroPipe,
        MonedaPipe,
        CompletarCerosPipe,
        EstadoSunatPipe,
        EstadoSummaryPipe,
        EstadoOrdenRestaurantePipe,
        EstadoDetalleOrdenRestaurantePipe,
        EstadoMembresiaPipe,
        EstadoEmpleadoPipe,
        EstadoTrasladoInventarioPipe,
        MotivoTrasladoPipe,
        TipoTransportePipe,
        DateAgoPipe,
        TipoGuiaPipe,
        TipoPrecioPipe,
        TipoProductoPipe,
        TipoPedidoPipe,
        EstadoPedidoOnlinePipe,
        ConceptoMovimientoPipe,
        EstadoPedidoRestaurantePipe,
        ActionsComponent,
        ReadonlyDirective,
        PermissionDirective,
        GoogleAutocompletePlaceComponent
    ],
    imports: [
        RippleModule,
        CommonModule,
        FormsModule,
        PickListModule,
        HttpClientModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CheckboxModule,
        ConfirmDialogModule,
        ContextMenuModule,
        DialogModule,
        DynamicDialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        ProgressBarModule,
        RadioButtonModule,
        ScrollPanelModule,
        SelectButtonModule,
        AccordionModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        SidebarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        ReactiveFormsModule,
        KeyFilterModule,
        ProgressSpinnerModule,
        NgxSpinnerModule,
        MultiSelectModule,
        ChartModule,
        DragAndDropModule,
        FullCalendarModule,
        DividerModule,
        TagModule,
        NgxsFormPluginModule,
        AutoFocusModule
    ],
    exports: [
        RippleModule,
        CommonModule,
        FormsModule,
        PickListModule,
        HttpClientModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CheckboxModule,
        ConfirmDialogModule,
        ContextMenuModule,
        DialogModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        ProgressBarModule,
        RadioButtonModule,
        ScrollPanelModule,
        SelectButtonModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        SidebarModule,
        TreeTableModule,
        VirtualScrollerModule,
        ReactiveFormsModule,
        KeyFilterModule,
        ProgressSpinnerModule,
        NgxSpinnerModule,
        AccordionModule,
        MultiSelectModule,
        ChartModule,
        EstadoComprobantePipe,
        TipoDocumentoPipe,
        TipoDocumentoIdentidadPipe,
        MontoDineroPipe,
        MonedaPipe,
        CompletarCerosPipe,
        EstadoSunatPipe,
        EstadoSummaryPipe,
        EstadoComprobantePipe,
        EstadoOrdenRestaurantePipe,
        EstadoDetalleOrdenRestaurantePipe,
        EstadoTrasladoInventarioPipe,
        EstadoMembresiaPipe,
        EstadoEmpleadoPipe,
        MotivoTrasladoPipe,
        TipoTransportePipe,
        DateAgoPipe,
        TipoGuiaPipe,
        TipoPrecioPipe,
        TipoProductoPipe,
        TipoPedidoPipe,
        EstadoPedidoOnlinePipe,
        ConceptoMovimientoPipe,
        EstadoPedidoRestaurantePipe,
        ActionsComponent,
        ReadonlyDirective,
        PermissionDirective,
        GoogleAutocompletePlaceComponent,
        DynamicDialogModule,
        DragAndDropModule,
        FullCalendarModule,
        DividerModule,
        TagModule,
        NgxsFormPluginModule,
        AutoFocusModule
    ],
})
export class SharedModule {
}
