import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {UnitCode} from '../domain/unit-code';


@Injectable({
  providedIn: 'root'
})
export class UnitCodeService {

    baseUrl: string = environment.endpointApi;
    baseUrlWeightScale: string = environment.balanzaEndpoint;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<UnitCode[]> {
        return this.http.get<UnitCode[]>(`${this.baseUrl}/units?${params}`);
    }

    addUnitCode(idEmpresa, model: UnitCode): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/units`, model);
    }

    removeUnitCode(idEmpresa, model: UnitCode): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/units`, model);
    }

    getActualValueWeightScale(): Observable<any> {
        return this.http.get<any>(`${this.baseUrlWeightScale}/weight`);
    }
}
