import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {TicketPrint} from '@app/domain/ventas/ticket-print';
import {CommandPrint} from '@app/domain/restaurante/command-print';
import {CheckPrint} from '@app/domain/restaurante/check-print';
import {Observable} from 'rxjs';
import {CashRegisterPrint} from '@app/domain/cash-register-print';
import {TicketFeePrint} from "@app/domain/restaurante/ticket-fee-print";

@Injectable({
    providedIn: 'root'
})
export class QzEscPosService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    invoiceEscPos(id, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/invoice/${id}`).subscribe((data: TicketPrint) => {

                // The dot size of the QR code
                const dots = '\x06';

                // Some proprietary size calculation
                const qrLength = data.qr.length + 3;
                const size1 =  String.fromCharCode(qrLength % 256);
                const size0 = String.fromCharCode(Math.floor(qrLength / 256));
                let separator = '------------------------------------------------';
                let headerItems = 'PRODUCTO              CANT.  P.U.   TOT.  ';
                if (options.paperWidth && options.paperWidth === 32) {
                    separator = '-------------------------------';
                    headerItems = 'PROD.         CANT. P.U.  TOT.';
                }

                const lines = [];
                data.items.forEach(it => {
                    it.descripcion = it.descripcion.toUpperCase();
                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.paperWidth && options.paperWidth === 32) {
                        widthDes = 22;
                        linesDescription = it.descripcion.match(/.{1,22}/g);
                    } else {
                        widthDes = 24;
                        linesDescription = it.descripcion.match(/.{1,24}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            if (options.paperWidth && options.paperWidth === 32) {
                                lines.push(`${ld.padEnd(widthDes)} ${(it.cantidad).padEnd(5)} ${it.precioUnitario.padEnd(6)} ${it.total}` + '\x0A');
                            } else {
                                lines.push(`${ld.padEnd(widthDes)}     ${(it.cantidad + ' U').padEnd(8)} ${it.precioUnitario.padEnd(8)} ${it.total}` + '\x0A');
                            }
                        } else {
                            lines.push(`${ld.padEnd(widthDes)}` + '\x0A');
                        }
                        iLineDes++;
                    });
                });

                const isNotaSunat = data.tipoComprobante === '07' || data.tipoComprobante === '08';
                const arrEscPos = [
                    ...(data.logo && options.imprimirLogoTicket) ? [{ type: 'raw', format: 'image', flavor: 'file', data: data.logo,
                        options: { language: 'ESCPOS', dotDensity: 'double' } }] : [],
                    '\x1B' + '\x40',          // init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x4D' + '\x31', // small text
                    data.razonSocial + '\x0A',
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x45' + '\x0D', // bold on
                    data.nombreComercial + '\x0A',
                    'RUC ' + data.rucEmisor + '\x0A',
                    '\x1B' + '\x45' + '\x0A', // bold off
                    data.direccionEmisor + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    `Teléf.: ${data.telefonoEmisor}    e-mail: ${data.emailEmisor}` + '\x0A',
                    // '\x0A',                   // line break
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A',
                    '\x1B' + '\x45' + '\x0D', // bold on
                    data.nombreComprobante + '\x0A',
                    data.serieCorrelativo + '\x0A',
                    '\x1B' + '\x45' + '\x0A', // bold off
                    separator + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    '\x1B' + '\x45' + '\x0D' + 'Fecha: ' + '\x1B' + '\x45' + '\x0A' + data.fechaEmision + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Cliente: ' + '\x1B' + '\x45' + '\x0A' + data.denominacionReceptor + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + `${data.tipoDocumentoReceptor}: ` + '\x1B' + '\x45' + '\x0A' + data.numeroDocumentoReceptor + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Moneda: ' + '\x1B' + '\x45' + '\x0A' + data.codigoMoneda + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    ...isNotaSunat ? ['\x1B' + '\x45' + '\x0D' + 'Comprobante afectado: ' + '\x1B' + '\x45' + '\x0A' + `${data.nombreComprobanteAfectado} ${data.serieAfectado}-${data.numeroAfectado}` + '\x0A'] : [],
                    ...isNotaSunat ? ['\x1B' + '\x45' + '\x0D' + 'Motivo nota: ' + '\x1B' + '\x45' + '\x0A' + `${data.motivoNota}` + '\x0A'] : [],
                    '\x1B' + '\x45' + '\x0D' + 'Direccion: ' + '\x1B' + '\x45' + '\x0A' + data.direccionReceptor + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    ...data.adicionales.length ? data.adicionales
                        .map(ad => '\x1B' + '\x45' + '\x0D' + `${ad.nombreCampo}: ` + '\x1B' + '\x45' + '\x0A' + ad.valorCampo + '\x0A') : [],
                    '\x0A',                   // line break
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...data.items ? lines : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A',
                    '\x1B' + '\x61' + '\x32', // right align
                    ...data.tipoComprobante !== 'NV' ? ['\x1B' + '\x45' + '\x0D' + 'OP. GRAVADA: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.gravada}` + '\x0A'] : [],
                    ...data.exonerada !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'OP. EXONERADA: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.exonerada}` + '\x0A'] : [],
                    ...data.gratuita !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'OP. GRATUITA: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.gratuita}` + '\x0A'] : [],
                    ...data.inafecta !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'OP. INAFECTA: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.inafecta}` + '\x0A'] : [],
                    '\x1B' + '\x45' + '\x0D' + 'DESCUENTO: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.descuento}` + '\x0A',
                    ...data.tipoComprobante !== 'NV' ? ['\x1B' + '\x45' + '\x0D' + 'IGV: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.igv}` + '\x0A'] : [],
                    ...data.isc !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'ISC: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.isc}` + '\x0A'] : [],
                    ...data.tributosBolsas !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'ICBP: ' + '\x1B' + '\x45' + '\x0A'
                    + `${data.monedaSimbolo} ${data.tributosBolsas}` + '\x0A'] : [],
                    ...data.otrosCargos !== '0.00' ? ['\x1B' + '\x45' + '\x0D' + 'OTROS CARGOS: ' + '\x1B' + '\x45' + '\x0A'
                    + `${data.monedaSimbolo} ${data.otrosCargos}` + '\x0A'] : [],
                    '\x1B' + '\x45' + '\x0D' + 'TOTAL: ' + '\x1B' + '\x45' + '\x0A' + `${data.monedaSimbolo} ${data.totalVenta}` + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    separator + '\x0A',
                    '\x1B' + '\x45' + '\x0D' + 'Vendedor: ' + '\x1B' + '\x45' + '\x0A' + data.vendedor + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Tipo venta: ' + '\x1B' + '\x45' + '\x0A' + data.tipoVenta + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    ...data.observaciones ? ['\x1B' + '\x45' + '\x0D' + 'Observación: ' + '\x1B' + '\x45' + '\x0A' + data.observaciones + '\x0A'] : [],
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x45' + '\x0D', // bold on
                    '\x0A',
                    ...data.formasPago ? data.formasPago.map(fp => `${fp.nombre}: ${fp.monto}` + '\x0A') : [] ,
                    '\x1B' + '\x45' + '\x0A', // bold off
                    '\x1B' + '\x45' + '\x0D' + 'Efectivo: ' + '\x1B' + '\x45' + '\x0A' + data.efectivo + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Vuelto: ' + '\x1B' + '\x45' + '\x0A' + data.cambio + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x0A',
                    'SON: ' + data.montoLetras + '\x0A',

                    ...data.tipoComprobante !== 'NV' ? [
                        // <!-- BEGIN QR DATA -->
                        '\x1D' + '\x28' + '\x6B' + '\x04' + '\x00' + '\x31' + '\x41' + '\x32' + '\x00',    // <Function 165> select the model (model 2 is widely supported)
                        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x43' + dots,               // <Function 167> set the size of the module
                        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x45' + '\x30',             // <Function 169> select level of error correction (48,49,50,51) printer-dependent
                        '\x1D' + '\x28' + '\x6B' + size1 + size0 + '\x31' + '\x50' + '\x30' + data.qr,          // <Function 080> send your data (testing 123) to the image storage area in the printer
                        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x51' + '\x30',              // <Function 081> print the symbol data in the symbol storage area
                        '\x1D' + '\x28' + '\x6B' + '\x03' + '\x00' + '\x31' + '\x52' + '\x30',              // <Function 082> Transmit the size information of the symbol data in the symbol storage area
                        // <!-- END QR DATA -->
                        '\x0A',
                        '\x1B' + '\x4D' + '\x31', // small text
                        `Representación impresa de la ${data.nombreComprobante}, autorizado mediante resolución N° 0340050001931 - SUNAT. Puede consultar este documento en ${data.urlConsultaComprobante}` + '\x0A',
                    ] : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x69',          // cut paper (old syntax)
// '\x1D' + '\x56'  + '\x00' // full cut (new syntax)
// '\x1D' + '\x56'  + '\x30' // full cut (new syntax)
// '\x1D' + '\x56'  + '\x01' // partial cut (new syntax)
// '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
                    '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                 // **for legacy drawer cable CD-005A.  Research before using.
                ];

                observer.next(arrEscPos);
                observer.complete();
            });
        });
    }


    commandEscPos(idCommand, params, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/command/${idCommand}?${params}`).subscribe((data: CommandPrint) => {
                let separator = '------------------------------------------------';
                let headerItems = 'PRODUCTO                                      ';
                if (options.paperWidth && options.paperWidth === 32) {
                    separator = '-------------------------------';
                    headerItems = 'PRODUCTO                       ';
                }
                const lines = [];
                data.items.forEach(it => {
                    it.producto = it.producto.toUpperCase();
                    it.extras = it.extras ? it.extras.toUpperCase() : it.extras;
                    let line;
                    let extras;
                    if (options.letraGrandeComanda) {
                        line = '\x1B' + '\x21' + '\x30' + it.cantidad + '-' + it.producto + '\x1B' + '\x21' + '\x0A';
                        extras = '\x1B' + '\x4D' + '\x30' + it.extras;
                    } else {
                        line = '\x1B' + '\x4D' + '\x30' + it.cantidad + '-' + it.producto + '\x1B' + '\x4D' + '\x31';
                        extras = '\x1B' + '\x4D' + '\x31' + it.extras;
                    }
                    if (it.extras) {
                        line = line + extras;
                    }
                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.paperWidth && options.paperWidth === 32) {
                        widthDes = 28;
                        linesDescription = line.match(/.{1,34}/g);
                    } else {
                        widthDes = 40;
                        linesDescription = line.match(/.{1,46}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            lines.push(`${ld.padEnd(widthDes)}  ` + '\x0A');
                        } else {
                            lines.push(`${ld.padEnd(widthDes)}` + '\x0A');
                        }
                        iLineDes++;
                    });
                });
                const arrEscPos = [
                    ...(data.logo && options.imprimirLogoTicket) ? [{ type: 'raw', format: 'image', flavor: 'file', data: data.logo,
                        options: { language: 'ESCPOS', dotDensity: 'double' } }] : [],
                    '\x1B' + '\x40',          // init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x45' + '\x0D', // bold on
                    'ORDEN ' + data.orden + '\x0A',
                    '\x1B' + '\x45' + '\x0A', // bold off
                    '\x0A',                   // line break
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x61' + '\x30', // left align
                    '\x1B' + '\x45' + '\x0D' + 'Fecha: ' + '\x1B' + '\x45' + '\x0A' + `${data.fecha}` + '\x1B' + '\x45' + '\x0D' + '    Hora: ' + '\x1B' + '\x45' + '\x0A' + `${data.hora}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Camarero: ' + '\x1B' + '\x45' + '\x0A' + `${data.camarero}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Cliente: ' + '\x1B' + '\x45' + '\x0A' + `${data.nombreCliente}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x0A',                   // line break
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    data.salon + '    Mesa: ' + `${data.mesa}` + '\x0A' + 'Area: ' + `${data.zona}`,
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    ...data.anulacion ? [
                        '\x0A' + '\x0A',                   // line break
                        '\x1B' + '\x61' + '\x31', // center align
                        '\x1B' + '\x21' + '\x30', // em mode on
                        'ANULACION',
                        '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    ] : [],
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    // '\x1B' + '\x4D' + '\x30', // normal text
                    ...lines.length ? lines : [],
                    separator + '\x0A',
                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x69',          // cut paper (old syntax)
                    '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                 // **for legacy drawer cable CD-005A.  Research before using.
                ];
                observer.next(arrEscPos);
                observer.complete();
            });
        });
    }


    checkEscPos(idCuenta, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/check-register/${idCuenta}`).subscribe((data: CheckPrint) => {
                let separator = '------------------------------------------------';
                let headerItems = 'PRODUCTO                CANT.   P.U.    IMP.    ';
                if (options.paperWidth && options.paperWidth === 32) {
                    separator = '-------------------------------';
                    headerItems = 'PROD.         CANT. P.U.  IMP.';
                }
                const lines = [];
                data.items.forEach(it => {

                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.paperWidth && options.paperWidth === 32) {
                        widthDes = 20;
                        linesDescription = it.producto.match(/.{1,20}/g);
                    } else {
                        widthDes = 30;
                        linesDescription = it.producto.match(/.{1,30}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            if (options.paperWidth && options.paperWidth === 32) {
                                lines.push(`${ld.padEnd(widthDes)} ${(it.cantidad).padEnd(6)} ${it.precioUnitario.padEnd(6)} ${it.precioVenta}` + '\x0A');
                            } else {
                                lines.push(`${ld.padEnd(widthDes)}  ${(it.cantidad + ' UND').padEnd(8)}  ${(it.precioUnitario).padEnd(8)}  ${(it.precioVenta).padEnd(8)}` + '\x0A');
                            }
                        } else {
                            lines.push(`${ld.padEnd(widthDes)}` + '\x0A');
                        }
                        iLineDes++;
                    });
                });
                const arrEscPos = [
                    ...(data.logo && options.imprimirLogoTicket) ? [{ type: 'raw', format: 'image', flavor: 'file', data: data.logo,
                        options: { language: 'ESCPOS', dotDensity: 'double' } }] : [],
                    '\x1B' + '\x40',          // init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x4D' + '\x31', // small text
                    data.razonSocial + '\x0A',
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x45' + '\x0D', // bold on
                    data.nombreComercial + '\x0A',
                   /* 'RUC ' + data.nuRuc + '\x0A',*/
                    '\x1B' + '\x45' + '\x0A', // bold off
                    data.direccion + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    `Teléf.: ${data.telefono}` + '\x0A',
                    // '\x0A',                   // line break
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x61' + '\x30', // left align
                    '\x1B' + '\x45' + '\x0D' + 'Fecha: ' + '\x1B' + '\x45' + '\x0A' + `${data.fecha}` + '\x1B' + '\x45' + '\x0D' + '    Hora: ' + '\x1B' + '\x45' + '\x0A' + `${data.hora}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Camarero: ' + '\x1B' + '\x45' + '\x0A' + `${data.camarero}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x0A',                   // line break
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    data.salon + '    Mesa: ' + `${data.mesa}`,
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...lines.length ? lines : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A',
                    '\x1B' + '\x61' + '\x32', // right align
                    '\x1B' + '\x45' + '\x0D' + 'TOTAL: S/' + '\x1B' + '\x45' + '\x0A' + `${data.totalPagar}` + '\x0A',
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x0A',
                    'SON: ' + data.montoLetras + '\x0A',
                    '\x0A',
                    ...data.url ? [data.url] : [] + '\x0A',
                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x69',          // cut paper (old syntax)
                    '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                 // **for legacy drawer cable CD-005A.  Research before using.
                ];

                observer.next(arrEscPos);
                observer.complete();
            });
        });
    }

    cashRegisterEscPos(id, params, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/cash-register/${id}?${params}`)
                .subscribe((data: CashRegisterPrint) => {
                let separator = '------------------------------------------------';
                let headerItems = 'CONCEPTO                       MONTO            ';
                let headerItemsVentasDetalladas = 'DESC.                        CANT.       TOTAL  ';
                if (options.paperWidth && options.paperWidth === 32) {
                    separator = '-------------------------------';
                    headerItems = 'CONC.                 MONT.    ';
                    headerItemsVentasDetalladas = 'DESC.          CANT.   TOTAL   ';
                }

                const linesPaymentsMethods = [];
                data.montosPagosIngresos.forEach(it => {
                    let iLineDes = 0;
                    let linesDescription: any[];
                    let widthDes;
                    if (options.paperWidth && options.paperWidth === 32) {
                        widthDes = 28;
                        linesDescription = it.metodo.match(/.{1,28}/g);
                    } else {
                        widthDes = 40;
                        linesDescription = it.metodo.match(/.{1,40}/g);
                    }
                    linesDescription.forEach(ld => {
                        if (iLineDes === 0) {
                            linesPaymentsMethods.push(`${ld.padEnd(widthDes)}  ${(it.monto)}` + '\x0A');
                        } else {
                            linesPaymentsMethods.push(`${ld.padEnd(widthDes)}` + '\x0A');
                        }
                        iLineDes++;
                    });
                });

                const linesVentasDetalladas = [];
                if (data.ventasDetalladas) {
                    data.ventasDetalladas.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 32) {
                            widthDes = 22;
                            linesDescription = it.descripcion.match(/.{1,22}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.descripcion.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesVentasDetalladas.push(`${ld.padEnd(widthDes)}  ${(it.cantidad)}    ${(it.total)}` + '\x0A');
                            } else {
                                linesVentasDetalladas.push(`${ld.padEnd(widthDes)}` + '\x0A');
                            }
                            iLineDes++;
                        });
                    });
                }

                const linesOtrosIngresos = [];
                data.ingresos.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 32) {
                            widthDes = 28;
                            linesDescription = it.descripcion.match(/.{1,28}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.descripcion.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesOtrosIngresos.push(`${ld.padEnd(widthDes)}  ${(it.monto)}` + '\x0A');
                            } else {
                                linesOtrosIngresos.push(`${ld.padEnd(widthDes)}` + '\x0A');
                            }
                            iLineDes++;
                        });
                    });


                const linesOtrosEgresos = [];
                data.egresos.forEach(it => {
                        let iLineDes = 0;
                        let linesDescription: any[];
                        let widthDes;
                        if (options.paperWidth && options.paperWidth === 32) {
                            widthDes = 28;
                            linesDescription = it.descripcion.match(/.{1,28}/g);
                        } else {
                            widthDes = 40;
                            linesDescription = it.descripcion.match(/.{1,40}/g);
                        }
                        linesDescription.forEach(ld => {
                            if (iLineDes === 0) {
                                linesOtrosEgresos.push(`${ld.padEnd(widthDes)}  ${(it.monto)}` + '\x0A');
                            } else {
                                linesOtrosEgresos.push(`${ld.padEnd(widthDes)}` + '\x0A');
                            }
                            iLineDes++;
                        });
                    });

                const arrEscPos = [
                    '\x1B' + '\x40',          // init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x45' + '\x0D', // bold on
                    'REPORTE DE CAJA ' + data.moneda + '\x0A',
                    '\x1B' + '\x45' + '\x0A', // bold off
                    '\x0A',                   // line break
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x61' + '\x30', // left align
                    '\x1B' + '\x45' + '\x0D' + 'Fecha: ' + '\x1B' + '\x45' + '\x0A' + `${data.fecha}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Hora de inicio: ' + '\x1B' + '\x45' + '\x0A' + `${data.horaInicio}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Hora de cierre: ' + '\x1B' + '\x45' + '\x0A' + `${data.horaCierre}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Turno: ' + '\x1B' + '\x45' + '\x0A' + `${data.turno}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Vendedor: ' + '\x1B' + '\x45' + '\x0A' + `${data.usuario}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Estado: ' + '\x1B' + '\x45' + '\x0A' + `${data.estadoCaja}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Punto de venta: ' + '\x1B' + '\x45' + '\x0A' + `${data.puntoVenta}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Estación: ' + '\x1B' + '\x45' + '\x0A' + `${data.estacionVenta}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Moneda: ' + '\x1B' + '\x45' + '\x0A' + `${data.moneda}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x0A',                   // line break
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'BALANCE',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    '\x1B' + '\x45' + '\x0D' + 'APERTURA                   ' + '\x1B' + '\x45' + '\x0A' + `${data.montoApertura}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'VENTAS EFECTIVO            ' + '\x1B' + '\x45' + '\x0A' + `${data.montoVentasEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'OTROS INGRESOS             ' + '\x1B' + '\x45' + '\x0A' + `${data.montoOtrosIngresosEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'OTROS EGRESOS              ' + '\x1B' + '\x45' + '\x0A' + `${data.montoOtrosEgresosEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'TOTAL                      ' + '\x1B' + '\x45' + '\x0A' + `${data.montoCajaEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'CIERRE                     ' + '\x1B' + '\x45' + '\x0A' + `${data.montoCierreEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'VENTAS',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    '\x1B' + '\x45' + '\x0D' + 'VENTAS EFECTIVO            ' + '\x1B' + '\x45' + '\x0A' + `${data.montoVentasEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'VENTAS TARJ., TRANS.       ' + '\x1B' + '\x45' + '\x0A' + `${data.montoVentasNoEfectivo}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'VENTAS CREDITO             ' + '\x1B' + '\x45' + '\x0A' + `${data.montoVentasCredito}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'TOTAL                      ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentas}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'VENTAS POR DOCUMENTO',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    '\x1B' + '\x45' + '\x0D' + 'FACTURA                    ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentasFactura}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'BOLETA                     ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentasBoleta}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'NOTA DE VENTA              ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentasNotaVenta}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'NOTA DE CREDITO            ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentasNotaCredito}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'NOTA DE DEBITO             ' + '\x1B' + '\x45' + '\x0A' + `${data.montoTotalVentasNotaDebito}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'METODOS DE PAGO (INGR.)',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...linesPaymentsMethods.length ? linesPaymentsMethods : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    ...data.detallado ? ['\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'VENTAS DETALLADAS',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItemsVentasDetalladas + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...linesVentasDetalladas.length ? linesVentasDetalladas : [],
                    '\x1B' + '\x45' + '\x0D' + 'TOTAL VENTAS          ' + '\x1B' + '\x45' + '\x0A' + `${data.totalVentasDetalladas}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A'] : [],

                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'OTROS INGRESOS',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...linesOtrosIngresos.length ? linesOtrosIngresos : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x21' + '\x30', // em mode on
                    'OTROS EGRESOS',
                    '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
                    '\x0A' + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    headerItems + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    ...linesOtrosEgresos.length ? linesOtrosEgresos : [],
                    '\x1B' + '\x4D' + '\x30', // normal text
                    separator + '\x0A' + '\x0A' + '\x0A',

                    '\x0A' + '\x0A' + '\x0A' + '\x0A',
                    separator + '\x0A',
                    '\x1B' + '\x61' + '\x31', // center align
                    data.usuario,

                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x69',          // cut paper (old syntax)
                    '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                 // **for legacy drawer cable CD-005A.  Research before using.
                ];

                observer.next(arrEscPos);
                observer.complete();
            });
        });
    }

    ticketFeeEscPos(idTicketFee, options?: any): Observable<any[]> {
        return new Observable<any[]>(observer => {
            this.http.get(`${this.baseUrl}/ticket-esc-pos/ticket-fee/${idTicketFee}`).subscribe((data: TicketFeePrint) => {
                const separator = '------------------------------------------------';
                const lines = [];
                const arrEscPos = [
                    ...(data.logo && options.imprimirLogoTicket) ? [{ type: 'raw', format: 'image', flavor: 'file', data: data.logo,
                        options: { language: 'ESCPOS', dotDensity: 'double' } }] : [],
                    '\x1B' + '\x40',          // init
                    '\x1B' + '\x61' + '\x31', // center align
                    '\x1B' + '\x4D' + '\x31', // small text
                    data.razonSocial + '\x0A',
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x45' + '\x0D', // bold on
                    data.nombreComercial + '\x0A',
                    /* 'RUC ' + data.nuRuc + '\x0A',*/
                    '\x1B' + '\x45' + '\x0A', // bold off
                    data.direccionEmisor + '\x0A',
                    '\x1B' + '\x4D' + '\x31', // small text
                    `Teléf.: ${data.telefonoEmisor}` + '\x0A',
                    // '\x0A',                   // line break
                    '\x1B' + '\x4D' + '\x30', // normal text
                    '\x1B' + '\x61' + '\x30', // left align
                    '\x1B' + '\x45' + '\x0D' + 'Comprobante: ' + '\x1B' + '\x45' + '\x0A' + `${data.comprobante}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Letra: ' + '\x1B' + '\x45' + '\x0A' + `${data.numero}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Fecha emisión: ' + '\x1B' + '\x45' + '\x0A' + `${data.fechaEmision}` + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Fecha vencimiento: ' + '\x1B' + '\x45' + '\x0A' + `${data.fechaVencimiento}` + '\x0A' + '\x0A',     // LABEL BOLD, DESCRIPTION NO BOLD
                    '\x1B' + '\x45' + '\x0D' + 'Importe: ' + '\x1B' + '\x45' + '\x0A' + `${data.importe}` + '\x0A',
                    '\x0A',                   // line break
                    '\x1B' + '\x45' + '\x0D' + 'Aceptante: ' + '\x1B' + '\x45' + '\x0A' + `${data.denominacionReceptor}` + '\x0A',
                    '\x1B' + '\x45' + '\x0D' + 'Domicilio: ' + '\x1B' + '\x45' + '\x0A' + `${data.direccionReceptor}` + '\x0A',
                    '\x1B' + '\x45' + '\x0D' + 'Ruc: ' + '\x1B' + '\x45' + '\x0A' + `${data.numeroDocumentoReceptor}` + '\x0A',
                    '\x1B' + '\x45' + '\x0D' + 'Telefono: ' + '\x1B' + '\x45' + '\x0A' + `${data.telefonoReceptor}` + '\x0A',
                    '\x1B' + '\x61' + '\x30', // left align
                    // separator + '\x0A',
                    separator + '\x0A',
                    '\x0A',
                    ...data.urlEmisor ? [data.urlEmisor] : [] + '\x0A',
                    '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',

                    '\x1B' + '\x69',          // cut paper (old syntax)
                    '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
                                                                 // **for legacy drawer cable CD-005A.  Research before using.
                ];
                observer.next(arrEscPos);
                observer.complete();
            });
        });
    }

}
