import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Ticket} from '../domain/ventas/ticket';
import {map} from 'rxjs';
import {Summary} from '@app/domain/summary';
import {Page} from '@app/domain/page';
import {Voided} from '@app/domain/voided';
import {DomSanitizer} from '@angular/platform-browser';
import {Printer} from '@app/domain/printer';
import {QzTrayService} from '@app/service/qz-tray.service';
import {TotalVentas} from '@app/domain/total-ventas';
import {PdfPrint} from '@app/domain/pdf-print';
import {TicketDetail} from '@app/domain/ventas/ticket-detail';
import {getTextEmailFromTicket, promiseBlob2Data} from '@app/utils/util-functions';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {Observable} from 'rxjs';
import {WhatsappService} from '@app/service/whatsapp.service';
import {LoginService} from '@app/service/login.service';
import {TotalUtilidad} from '@app/domain/total-utilidad';
import {EditPurchaseUnitPrice} from '@app/domain/edit-purchase-unit-price';
import {DataSend} from '@app/domain/data-send';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';
import {SunatResponse} from '@app/domain/ventas/sunat-response';
import {Company} from '@app/domain/company';
import {TotalVentasFormaPago} from '@app/domain/total-ventas-forma-pago';
import {TicketDispatch} from "@app/domain/ticket-dispatch";


@Injectable({
    providedIn: 'root'
})
export class TicketService {

    private baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient,
                private sanitizer: DomSanitizer,
                private qzTrayService: QzTrayService,
                private qzEscPosService: QzEscPosService,
                private coffeEscPosService: CoffeEscPosService,
                private whatsappService: WhatsappService,
                private loginService: LoginService) {
    }

    findAllPage(params: string): Observable<Page<Ticket>> {
        return this.http.get<Page<Ticket>>(`${this.baseUrl}/tickets?${params}`);
    }

    findAllList(params: string): Observable<Ticket[]> {
        return this.http.get<Ticket[]>(`${this.baseUrl}/tickets?${params}`);
    }

    findAllTicketDetailsPage(params: string): Observable<Page<TicketDetail>> {
        return this.http.get<Page<TicketDetail>>(`${this.baseUrl}/tickets-details?${params}`);
    }

    editPurchaseUnitPrice(id, editPurchaseUnitPrice: EditPurchaseUnitPrice): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/tickets-details/purchase-product-price/${id}`, editPurchaseUnitPrice);
    }

    totales(params: string): Observable<TotalVentas[]> {
        return this.http.get<TotalVentas[]>(`${this.baseUrl}/tickets/operations/totales?${params}`);
    }

    totalesFormaPago(params: string): Observable<TotalVentasFormaPago[]> {
        return this.http.get<TotalVentasFormaPago[]>(`${this.baseUrl}/tickets/operations/totales-forma-pago?${params}`);
    }

    totalesUtilidad(params: string): Observable<TotalUtilidad[]> {
        return this.http.get<TotalUtilidad[]>(`${this.baseUrl}/tickets/operations/totales-utilidad?${params}`);
    }

    findAllForMonitorPage(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/root/monitor/tickets?${params}`);
    }

    countAllForMonitorPage(params: string): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/root/monitor/tickets-count?${params}`);
    }


    findSunatResponse(id): Observable<SunatResponse[]> {
        return this.http.get<SunatResponse[]>(`${this.baseUrl}/root/monitor/tickets/${id}/sunat-responses`);
    }

    enviarSunatFromMonitor(id): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/root/monitor/tickets/operations/send-sunat/${id}`, null);
    }

    runProccessMonitor(data): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/root/monitor/tickets/start-process`, data);
    }

    runProccessMonitorContingency(data): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/root/monitor/tickets/start-process-contingency`, data);
    }

    generateNewNumberFromMonitor(model): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/root/monitor/tickets/operations/generate-new-number`, model);
    }

    getStatusCdr(id): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/root/monitor/tickets/getStatusCdr/${id}`, null);
    }

    getStatusSummaries(): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/root/monitor/summary/getStatus`, null);
    }

    getStatusApi(identificador): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/root/monitor/tickets/getStatusApi/${identificador}`, null);
    }

    changeStatusTicketManual(id, estado): Observable<any> {
        return this.http.patch<any>(`${this.baseUrl}/root/monitor/tickets/change-status/${id}/${estado}`, null);
    }

    getNextNumber(params): Observable<any> {
        return this.http.get(`${this.baseUrl}/tickets/operations/next-number?${params}`);
    }

    findById(id, hideLoader?): Observable<Ticket> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get(`${this.baseUrl}/tickets/${id}`, options);
    }

    editContactById(id, model): Observable<void> {
        return this.http.patch<void>(`${this.baseUrl}/tickets/${id}/contact-data`, model);
    }

    save(model: Ticket): Observable<Ticket> {
        return this.http.post<Ticket>(`${this.baseUrl}/tickets`, model);
    }

    enviarSunat(id, hideLoader?): Observable<void> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.post<void>(`${this.baseUrl}/tickets/operations/send-sunat/${id}`, null, options);
    }

    consultarCdrSunat(id, hideLoader?): Observable<any> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get<any>(`${this.baseUrl}/tickets/getStatusCdr/${id}`, options);
    }

    validateSendBill(id, hideLoader?): Observable<any> {
        let options: {};
        if (hideLoader) {
            options = {
                headers: {hideLoader: 'S'}
            };
        } else {
            options = {};
        }
        return this.http.get<any>(`${this.baseUrl}/tickets/validateSendBill/${id}`, options);
    }


    update(model: Ticket): Observable<Ticket> {
        return this.http.put<Ticket>(`${this.baseUrl}/tickets`, model);
    }

    openPdf(ticket: Ticket, options: any): Observable<any> {
        const url = this.getUrlPdf(ticket, options.type);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    if (options.escPos) {
                        if (options.clienteImpresion === 'QZ') {
                            this.qzEscPosService.invoiceEscPos(ticket.id, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        } else {
                            this.coffeEscPosService.invoiceEscPos(ticket.id, options).subscribe(escPos => {
                                response.escPos = escPos;
                                observer.next(response);
                                observer.complete();
                            });
                        }
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                });
            });
        });
    }

    openPdfDespacho(ticket: Ticket): Observable<any> {
        const url = this.getUrlPdfDespacho(ticket);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        originalUrl: url,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    getDataSend(ticket: Ticket): DataSend {
        const url = `${this.getUrlPdf(ticket, 'A4')}&time=${new Date().getTime()}`;
        return  {
            id: ticket.id,
            nameMessage: `${ticket.serie}-${ticket.numero}-${new Date().getTime()}`,
            archivos: [],
            asunto: `Comprobante electrónico ${ticket.serie}-${ticket.numero}`,
            emails: [...ticket.emailReceptor ? [ticket.emailReceptor] : []],
            number: ticket.telefonoReceptor,
            texto: getTextEmailFromTicket(ticket),
            url,
            messageWhatsappWeb: `Hola! *${this.loginService.getLogin().user.empresa.nombreComercial.replace('&', 'Y')}* adjunta su comprobante electrónico con número ${ticket.serie}-${ticket.numero}, muchas gracias por su preferencia. Link del PDF: ${url}`,
            textMessage: `Hola! *${this.loginService.getLogin().user.empresa.nombreComercial}* adjunta su comprobante electrónico con número *${ticket.serie}-${ticket.numero}*, muchas gracias por su preferencia.`,
            type: 'ticket'
        };
    }

    anularComprobante(identificador, data): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/voided/${identificador}`, data);
    }

    sendEmail(model): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/tickets/operations/send-email`, model);
    }

    downloadPdfPublico(model): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/public/ticket`, model);
    }

    sendWhatsappTicketPublic(phoneNumber, model): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/public/ticket-whatsapp?number=${phoneNumber}`, model);
    }

    openPdfVoided(id): Promise<PdfPrint> {
        return new Promise(resolve => {
            this.descargaPdfComunicacionDeBaja(id).subscribe(urlLocal => {
                resolve(
                    {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + urlLocal),
                        url: urlLocal,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(urlLocal)
                    }
                );
            });
        });
    }

    descargaPdfComunicacionDeBaja(id): Observable<string> {
        return this.http.get(`${this.baseUrl}/voided/download-pdf/${id}`, {responseType: 'blob'})
            .pipe(map(data => window.URL.createObjectURL(new Blob([data], {type: 'application/pdf'}))));
    }

    findAllSearchSummary(params): Observable<Page<Summary>> {
        return this.http.get<Page<Summary>>(`${this.baseUrl}/summary?${params}`);
    }

    findAllSearchVoided(params): Observable<Page<Voided>> {
        return this.http.get<Page<Voided>>(`${this.baseUrl}/voided?${params}`);
    }

    createSummary(fecha: string, data: any, body?): Observable<any> {
        const params = [
            `rucEmisor=${data.ruc}`,
            `isAttached=${data.isAttached}`
        ];
        if (data.tiposComprobante) {
            params.push(`tipoComprobante=${data.tiposComprobante.join(',')}`);
        }
        if (data.tipoEnvio) {
            params.push(`tipoEnvio=${data.tipoEnvio}`);
        }
        return this.http.post<any>(`${this.baseUrl}/summary/${fecha}?${params.join('&')}`, body);
    }

    getStatusSummary(ticket): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/summary/${ticket}/status`, {});
    }

    getStatusVoided(ticket): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/voided/${ticket}/status`, {});
    }

    sendSunatVoided(id): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/voided/operations/send-sunat/${id}`, {});
    }

    dispatchProducts(id, data): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/tickets/${id}/operations/dispatch-products`, data);
    }

    voidedDispatchProducts(idTicket, idDispatch): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/tickets/${idTicket}/operations/voided-dispatch-products/${idDispatch}`, {});
    }


    findAllSearchSummaryForMonitor(params): Observable<Page<Summary>> {
        return this.http.get<Page<Summary>>(`${this.baseUrl}/root/monitor/summary?${params}`);
    }

    findAllSearchVoidedForMonitor(params): Observable<Page<Voided>> {
        return this.http.get<Page<Voided>>(`${this.baseUrl}/root/monitor/voided?${params}`);
    }

    getUrlPdf(ticket: Ticket, tipo: string) {
        return `${environment.endpointApi}/public/pdf/tickets/${ticket.uuid}/${ticket.identificadorDocumento}?tipo=${tipo}`;
    }

    printInQz(data, printer: Printer, escPos: boolean) {
        const options = {
            flavor: 'base64',
            escPos
        };
        this.qzTrayService.queue.next({printer, data, options});
    }

    getUrlPdfDespacho(ticket: Ticket) {
        return `${environment.endpointApi}/public/pdf/tickets/${ticket.uuid}/despacho/${ticket.identificadorDocumento}`;
    }

    findAllDispatchList(id: string, params): Observable<TicketDispatch[]> {
        return this.http.get<TicketDispatch[]>(`${this.baseUrl}/tickets/${id}/dispatchs?${params}`);
    }


}
