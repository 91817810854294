import {Component, Input, OnInit} from '@angular/core';
import { AppMainComponent } from './app.main.component';
import {environment} from '@env/environment';
import {menuOpciones} from './menu.opciones';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];
    urlImageLogo: string;

    // tslint:disable-next-line:variable-name
    private _roles: any[];
    get roles() {
        return this._roles;
    }

    @Input()
    set roles(roles: any[]) {
        this._roles = roles;
        this.loadMenu();
    }

    constructor(public appMain: AppMainComponent) {}

    ngOnInit() {
        this.urlImageLogo = environment.urlLogo;
    }

    loadMenu() {
        if (this.roles) {
            menuOpciones.forEach(mit => {
                this.iterateMenu(mit);
            });
            this.model = menuOpciones;
        }
    }

    iterateMenu(item: any) {
        let visible = false;
        const rolesRequired = [...item.rol];
        rolesRequired.forEach(rr => {
            if (this.roles.find(r => r === rr)) {
                visible = true;
            }
        });
        item.visible = visible;
        if (item.items) {
            item.items.forEach(it => {
                this.iterateMenu(it);
            });
        }
    }

    onMenuClick() {
        this.appMain.menuClick = true;
    }
}
