import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Delivery} from '@app/domain/delivery';
import {Page} from '@app/domain/page';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<Page<Delivery>> {
        return this.http.get<Page<Delivery>>(`${this.baseUrl}/deliveries?${params}`);
    }

    save(model: Delivery): Observable<Delivery> {
        return this.http.post<Delivery>(`${this.baseUrl}/deliveries`, model);
    }

    update(id, model: Delivery): Observable<Delivery> {
        return this.http.post<Delivery>(`${this.baseUrl}/deliveries/${id}`, model);
    }

    updateStatus(id, estado): Observable<void> {
        return this.http.patch<void>(`${this.baseUrl}/deliveries/${id}/estado?estado=${estado}`, null);
    }
}
