import {Action, Selector, State, StateContext} from '@ngxs/store';
import {
    SetBusquedaPedidos,
    SetFiltroEstadoDelivery,
    SetFiltrosTipoPedido
} from '@app/state/restaurant/pedidos/pedidos-restaurante.actions';
import {Injectable} from '@angular/core';

export class PedidosRestaurantStateModel {
    filtroTiposPedidos: any[];
    filtroEstadoDelivery: any[];
    filtroTipoCuentaPorCobrar: any;
    busquedaPedidos: any;
}

@Injectable()
@State<PedidosRestaurantStateModel>({
    name: 'restaurant_pedidos',
    defaults: {
        filtroTiposPedidos: ['LOCAL', 'PEDIDO_LOCAL', 'PEDIDO_FORANEO', 'PEDIDO_ONLINE'],
        filtroEstadoDelivery: null,
        filtroTipoCuentaPorCobrar: 'T',
        busquedaPedidos: {
            desde: new Date(),
            hasta: new Date(),
            estadosPedidosSeleccionados: ['PENDIENTE', 'PREPARADO', 'PRECUENTA']
        }
    }
})
export class PedidosRestauranteState {

    constructor() {
    }

    @Selector()
    static filtroTiposPedidos(state: PedidosRestaurantStateModel) {
        return state.filtroTiposPedidos;
    }

    @Selector()
    static filtroEstadoDelivery(state: PedidosRestaurantStateModel) {
        return state.filtroEstadoDelivery;
    }

    @Selector()
    static busquedaPedidos(state: PedidosRestaurantStateModel): any {
        return state.busquedaPedidos;
    }

    @Action(SetFiltrosTipoPedido)
    setFiltrosTipoPedido({getState, setState}: StateContext<PedidosRestaurantStateModel>, {filtroTiposPedidos}: SetFiltrosTipoPedido) {
        const state = getState();
        setState({
            ...state,
            filtroTiposPedidos,
        });
    }

    @Action(SetFiltroEstadoDelivery)
    setFiltroEstadoDelivery({getState, setState}: StateContext<PedidosRestaurantStateModel>, {filtroEstadoDelivery}:
        SetFiltroEstadoDelivery) {
        const state = getState();
        setState({
            ...state,
            filtroEstadoDelivery,
        });
    }

    @Action(SetBusquedaPedidos)
    setBusquedaPedidos({getState, setState}: StateContext<PedidosRestaurantStateModel>, {busquedaPedidos}: SetBusquedaPedidos) {
        const state = getState();
        setState({
            ...state,
            busquedaPedidos,
        });
    }
}
