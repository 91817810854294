import {EventEmitter, Injectable} from '@angular/core';
import * as KJUR from 'jsrsasign';

import {HttpClient} from '@angular/common/http';
import {Printer} from '@app/domain/printer';
import {forkJoin, map, Observable, Subject} from 'rxjs';
import { from } from 'rxjs';
import 'rxjs-compat/add/observable/throw';


declare var qz: any;

@Injectable()
export class QzTrayService {

    private optionsQz;
    private privateKey;
    private privatePem;
    private initialConfig: boolean;
    private _statusConnect = false;
    public changeStatusConnect: EventEmitter<boolean> = new EventEmitter();
    public queue = new Subject();

    constructor(private http: HttpClient) {
        this.optionsQz = {
            host: 'localhost'
        };
    }
    setStatus(status) {
        this._statusConnect = status;
        this.changeStatusConnect.emit(this._statusConnect);
    }

    getStatus() {
        return this._statusConnect;
    }

    getPrivateKey() {
        return this.http.get('/assets/printer/cert.txt', {responseType: 'text'});
    }

    getPrivatePem() {
        return this.http.get('/assets/printer/key.txt', {responseType: 'text'});
    }

    public isActiveQz() {
        return qz.websocket.isActive();
    }

    public getConnection() {
        return qz.websocket.getConnection();
    }

    public connectQZ(options): Observable<boolean> {
        return new Observable<boolean>(observer => {
            const fnConnect = () => {
                qz.websocket.connect(options).then(t => {
                    console.log(`Conectado correctamente a ${options.host}`);
                    this.optionsQz = options;
                    observer.next(true);
                    observer.complete();
                    this.setStatus(true);
                }).catch(err => {
                    console.error(err);
                    this.setStatus(false);
                    throw new Error(`Error al conectar con sel servidor de impresoras QZ`);
                });
            };
            this.initConfig().subscribe(() => {
                if (qz.websocket.isActive() && this.optionsQz && this.optionsQz.host.toString() === options.host.toString()) {
                    console.log(`Ya esta conectado a ${options.host}`);
                    observer.next(true);
                    observer.complete();
                } else if (qz.websocket.isActive() && this.optionsQz && this.optionsQz.host.toString() !== options.host.toString()) {
                    this.disconnect().then(() => {
                        console.log(`Desconectando ${this.optionsQz.host}`);
                        fnConnect();
                    });
                } else if (!qz.websocket.isActive()) {
                    fnConnect();
                }
            });
        });
    }

    initConfig(): Observable<boolean> {
        const fnSetInitConfigQz = (observer) => {
            qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
            qz.security.setCertificatePromise((resolve, reject) => {
                resolve(this.privateKey);
            });
            qz.security.setSignaturePromise((toSign) => {
                return (resolve, reject) => {
                    try {
                        const pk = KJUR.KEYUTIL.getKey(this.privatePem);
                        const sig = new KJUR.crypto.Signature({alg: 'SHA512withRSA'});
                        sig.init(pk);
                        sig.updateString(toSign);
                        const hex = sig.sign();
                        const signature = KJUR.stob64(KJUR.hextorstr(hex));
                        resolve(signature);
                    } catch (err) {
                        reject(err);
                    }
                };
            });
            qz.websocket.setClosedCallbacks((evt) => {
                this.setStatus(false);
                console.log(evt.reason);
            });
            setTimeout(() => {
                this.initialConfig = true;
                observer.next(true);
            }, 1000);
        };

        return new Observable<boolean>(observer => {
            if (this.initialConfig) {
                observer.next(true);
            } else {
                if (!this.privateKey || !this.privatePem) {
                    forkJoin(
                        [this.getPrivateKey(), this.getPrivatePem()]
                    ).subscribe(result => {
                        this.privateKey = result[0];
                        this.privatePem = result[1];
                        fnSetInitConfigQz(observer);
                    }, error => {
                        throw new Error(`Error al descargar el certificado de qz tray!`);
                    });
                } else {
                    fnSetInitConfigQz(observer);
                }
            }
        });
    }

    getPrinters(): Observable<string[]> {
        return from(qz.printers.find()).pipe(map((printers: string[]) => printers));
    }

    getPrinter(printerName: string): Observable<string> {
        return from(qz.printers.find(printerName)).pipe(map((printer: string) => printer));
    }

    getUpdatedConfig() {
        const config = qz.configs.create(null);
        config.reconfigure({
            units: 'mm',
            orientation: 'portrait'
        });
        return config;
    }

    // Print data to chosen printer
    printQz(printer: Printer, data: any, options?): Observable<any> {
        if (options && options.escPos) {
            return this.printEscPos(printer, data);
        } else {
            return this.printPdf(printer, data, options);
        }
    }

    printPdf(printer: Printer, data: any, options?): Observable<any> {
        return new Observable<any>(observer => {
            const config = this.getUpdatedConfig();
            config.setPrinter(printer.nombre);
            const printData = [
                {
                    type: options && options.type ? options.type : 'pixel',
                    format: options && options.format ? options.format : 'pdf',
                    flavor: options && options.flavor ? options.flavor : 'file',
                    data,
                    options: {
                        pageWidth: printer.anchoPapel,               //  width of content body
                        margin: '0 0 0 0',            // margin of content body
                        copies: 1,
                    }
                }
            ];
            console.log('printdata', printData);
            console.log('config', config);
            const fnQzPrint = () => {
                qz.print(config, printData).then(() => {
                    observer.next(true);
                    observer.complete();
                }).catch(err => {
                    observer.next(false);
                    observer.complete();
                });
            };
            this.connectQZ({host: printer.ip}).subscribe(c => {
                if (c) {
                    fnQzPrint();
                } else {
                    observer.next(false);
                    observer.complete();
                }
            }, error => {
                observer.next(false);
                observer.complete();
            });
        });
    }

    printEscPos(printer: Printer, data: any): Observable<any> {
        return new Observable<any>(observer => {
            const config = qz.configs.create(printer.nombre, { encoding: 'Cp850' });
            config.reconfigure({
                units: 'mm',
                orientation: 'portrait',
                encoding: 'Cp850'
            });
            const fnQzPrint = () => {
                qz.print(config, data).then(() => {
                    observer.next(true);
                    observer.complete();
                }).catch(err => {
                    observer.next(false);
                    observer.complete();
                });
            };
            this.connectQZ({host: printer.ip}).subscribe(c => {
                if (c) {
                    fnQzPrint();
                } else {
                    observer.next(false);
                    observer.complete();
                }
            }, error => {
                observer.next(false);
                observer.complete();
            });
        });
    }

    // Disconnect QZ Tray from the browser
    public disconnect(): Promise<any> {
        return new Promise<any>(resolve => {
            qz.websocket.disconnect().then(resolve).catch(resolve);
        });
    }
}

