import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Check} from '@app/domain/check';
import {
    AddIdPedido,
    ClearIdPedido,
    SetCuentaPorCobrar
} from '@app/state/restaurant/cobrador/cobrador-restaurante.actions';
import {Store} from '@ngxs/store';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Printer} from '@app/domain/printer';
import {QzTrayService} from '@app/service/qz-tray.service';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {QzEscPosService} from '@app/service/qz-esc-pos.service';
import {DataSend} from '@app/domain/data-send';
import {CoffeEscPosService} from '@app/service/coffe-esc-pos.service';

@Injectable({
    providedIn: 'root'
})
export class CheckService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient, private store: Store,
                private router: Router, private sanitizer: DomSanitizer,
                private qzTrayService: QzTrayService,
                private qzEscPosService: QzEscPosService,
                private coffeEscPosService: CoffeEscPosService) {
    }

    findById(id): Observable<Check> {
        return this.http.get<Check>(`${this.baseUrl}/checks/${id}`);
    }

    findAll(params): Observable<Check[]> {
        return this.http.get<Check[]>(`${this.baseUrl}/checks?${params}`);
    }

    update(id, model: Check): Observable<Check> {
        return this.http.put<Check>(`${this.baseUrl}/checks/${id}`, model);
    }

    goToPay(checks: Check[]) {
        if (checks) {
            const idOrders = [...new Set(checks.map(c => c.pedido.id))];
            if (checks.length === 1) {
                this.store.dispatch(new SetCuentaPorCobrar(checks[0]));
            } else {
                this.store.dispatch(new ClearIdPedido());
                idOrders.forEach(id => this.store.dispatch(new AddIdPedido(id)));
            }
            this.router.navigateByUrl('/restaurante/cobrador/cuentas');
        }
    }

    getUrlPdf(idCuenta) {
        return `${environment.endpointApi}/public/pdf/check-register/${idCuenta}`;
    }

    printQz(data, printer: Printer, escPos: boolean) {
        const options = {
            flavor: 'base64',
            escPos
        };
        this.qzTrayService.queue.next({printer, data, options});
    }


    openPdf(cuenta: Check, options): Observable<any> {
        const url = this.getUrlPdf(cuenta.id);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64,
                        originalUrl: url,
                        check: cuenta
                    };
                    if (options.escPos) {
                        if (options.clienteImpresion === 'QZ') {
                            this.qzEscPosService.checkEscPos(cuenta.id, options).subscribe(escPosData => {
                                response.escPos = escPosData;
                                observer.next(response);
                                observer.complete();
                            });
                        } else {
                            this.coffeEscPosService.checkEscPos(cuenta.id, options).subscribe(escPosData => {
                                response.escPos = escPosData;
                                observer.next(response);
                                observer.complete();
                            });
                        }
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                });
            });
        });
    }

    getDataSend(urlPdf, cuenta: Check): DataSend {
        const url = `${urlPdf}&time=${new Date().getTime()}`;
        return  {
            id: cuenta.id,
            nameMessage: `Tu cuenta ha sido generada-${new Date().getTime()}`,
            archivos: [],
            asunto: `Cuenta de consumo, mesa ${cuenta.pedido.mesa.numero}`,
            emails: [],
            number: '',
            texto: '',
            url,
            messageWhatsappWeb: `Hola! se le envía su cuenta de consumo de la mesa ${cuenta.pedido.mesa.numero}: ${url}`,
            textMessage: `Hola! se le envía su cuenta de consumo de la mesa ${cuenta.pedido.mesa.numero}`,
            type: 'ticket'
        };
    }
}
