import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Currency} from '@app/domain/currency';
import {Exchange} from '@app/domain/exchange';
import {tap} from 'rxjs/operators';
import {MessageService} from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService {

    baseUrl: string = environment.endpointApi;
    currencies: Currency[];

    constructor(private http: HttpClient, private messageService: MessageService) {
    }

    findAll(): Observable<Currency[]> {
        return this.http.get<Currency[]>(`${this.baseUrl}/currencies`).pipe(tap(cs => this.currencies = cs));
    }

    findById(id: number): Observable<Currency> {
        return this.http.get<Currency>(`${this.baseUrl}/currencies/${id}`);
    }

    save(model: Currency): Observable<Currency> {
        return this.http.post<Currency>(`${this.baseUrl}/currencies`, model);
    }

    updateTipoCambio(id, model: Currency): Observable<Currency> {
        return this.http.patch<Currency>(`${this.baseUrl}/currencies/${id}/tipoCambio`, model);
    }

    updateMonedaNacional(id, model: Currency): Observable<Currency> {
        return this.http.patch<Currency>(`${this.baseUrl}/currencies/${id}/monedaNacional`, model);
    }

    updateMonedaPorDefecto(id, model: Currency): Observable<Currency> {
        return this.http.patch<Currency>(`${this.baseUrl}/currencies/${id}/monedaPorDefecto`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/currencies/${id}`);
    }

    exchange(model: Exchange): number {
        if (!model.montoOrigen) {
            model.montoOrigen = 0;
        }
        const monedaOrigen: Currency = this.currencies.find(c => c.codigoMoneda === model.codigoMonedaOrigen && c.tipoCambio);
        if (!monedaOrigen) {
            const messageError = `Debe registrar el tipo de cambio para la moneda ${model.codigoMonedaOrigen}`;
            this.messageService.add({severity: 'error', summary: 'Error', detail: messageError});
            throw new Error(messageError);
        }
        const monedaDestino: Currency = this.currencies.find(c => c.codigoMoneda === model.codigoMonedaDestino && c.tipoCambio);
        if (!monedaDestino) {
            const messageError = `Debe registrar el tipo de cambio para la moneda ${model.codigoMonedaDestino}`;
            this.messageService.add({severity: 'error', summary: 'Error', detail: messageError});
            throw new Error(messageError);
        }
        const montoEnMonedaNacional = Number(model.montoOrigen) * Number(monedaOrigen.tipoCambio);
        return  montoEnMonedaNacional / Number(monedaDestino.tipoCambio);
    }
}
