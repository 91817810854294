import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Sales} from '@app/domain/sales';
import {TotalCategory} from '@app/domain/total-category';
import {Movements} from "@app/domain/movements";


@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllSales(params): Observable<Sales[]> {
        return this.http.get<Sales[]>(`${this.baseUrl}/sales-months?${params}`);
    }

    findAllMovements(params): Observable<Movements[]> {
        return this.http.get<Movements[]>(`${this.baseUrl}/movements-months?${params}`);
    }

    findAllTotalCategories(params): Observable<TotalCategory[]> {
        return this.http.get<Sales[]>(`${this.baseUrl}/total-categories?${params}`);
    }

}
