import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Person} from '@app/domain/person';

@Injectable({
    providedIn: 'root'
})
export class PersonService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params: string): Observable<Person[]> {
        return this.http.get<Person[]>(`${this.baseUrl}/persons?${params}`);
    }
}
