import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {PaymentMethod} from '@app/domain/payment-method';


@Injectable({
    providedIn: 'root'
})
export class PaymentMethodService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<PaymentMethod[]> {
        return this.http.get<PaymentMethod[]>(`${this.baseUrl}/payment-methods`);
    }

    findById(id): Observable<PaymentMethod> {
        return this.http.get(`${this.baseUrl}/payment-methods/${id}`);
    }

    save(model: PaymentMethod): Observable<PaymentMethod> {
        return this.http.post<PaymentMethod>(`${this.baseUrl}/payment-methods`, model);
    }

    edit(id, model: PaymentMethod): Observable<PaymentMethod> {
        return this.http.put<PaymentMethod>(`${this.baseUrl}/payment-methods/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/payment-methods/${id}`);
    }
}
