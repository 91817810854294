// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const menuOpciones = [
    {
        label: 'Super Usuario', icon: 'fa fa-unlock-alt',
        items: [
            { label: 'Empresas', icon: 'pi pi-star', routerLink: ['/empresas'], rol: ['SUPER_USUARIO'] },
            { label: 'Plantillas', icon: 'pi pi-star', routerLink: ['/plantillas'], rol: ['SUPER_USUARIO'] },
            { label: 'Monitoreo', icon: 'pi pi-star', routerLink: ['/monitoreo'], rol: ['SUPER_USUARIO'] },
            { label: 'Control de empresas', icon: 'pi pi-star', routerLink: ['/control-empresas'], rol: ['SUPER_USUARIO'] },
            {
                label: 'Cuotas',
                icon: 'pi pi-star',
                routerLink: ['/cuotas'],
                rol: ['SUPER_USUARIO']
            },
        ],
        rol: ['SUPER_USUARIO']
    },

    {
        label: 'Dashboard',
        icon: 'fa fa-pie-chart',
        routerLink: ['/dashboard'],
        rol: ['SUPER_USUARIO', 'DASHBOARD_VER']
    },
    {
        label: 'Administración', icon: 'fa fa-table',
        items: [
            {
                label: 'Certificados digitales',
                icon: 'fa fa-certificate',
                routerLink: ['/certificados'],
                rol: ['SUPER_USUARIO', 'CERTIFICADOS_VER']
            },
            {
                label: 'Empresas adjuntas',
                icon: 'pi pi-star',
                routerLink: ['/empresas/adjuntas'],
                rol: ['SUPER_USUARIO', 'EMPRESAS_ADJUNTAS_VER']
            },
            {
                label: 'Ciudades',
                icon: 'fa fa-building',
                routerLink: ['/ciudades'],
                rol: ['SUPER_USUARIO', 'CIUDADES_VER']
            },
            {
                label: 'Unidades de medida',
                icon: 'fa fa-balance-scale',
                routerLink: ['/unidades'],
                rol: ['SUPER_USUARIO', 'UNIDADES_VER']
            },
            {
                label: 'Métodos de pago',
                icon: 'fa fa-credit-card',
                routerLink: ['/formas-pago'],
                rol: ['SUPER_USUARIO', 'FORMAS_PAGO_VER']
            },
            {
                label: 'Monedas',
                icon: 'fa fa-usd',
                routerLink: ['/monedas'],
                rol: ['SUPER_USUARIO', 'MONEDAS_VER']
            },
            {
                label: 'Bancos',
                icon: 'fa fa-university',
                routerLink: ['/bancos'],
                rol: ['SUPER_USUARIO', 'BANCOS_VER']
            },
            {
                label: 'Cuentas bancarias',
                icon: 'fa fa-credit-card-alt',
                routerLink: ['/cuentas-bancarias'],
                rol: ['SUPER_USUARIO', 'CUENTAS_BANCARIAS_VER']
            },
            {
                label: 'Horarios',
                icon: 'fa fa-clock-o',
                routerLink: ['/horarios'],
                rol: ['SUPER_USUARIO', 'PUNTO_VENTA_VER']
            },
            {
                label: 'Puntos de venta',
                icon: 'fa fa-street-view',
                routerLink: ['/puntos-venta'],
                rol: ['SUPER_USUARIO', 'PUNTO_VENTA_VER']
            },
            {
                label: 'Estaciones de venta',
                icon: 'fa fa-shopping-bag',
                routerLink: ['/estaciones-venta'],
                rol: ['SUPER_USUARIO', 'SALE_STATION_VER']
            },
            {
                label: 'Series documentos',
                icon: 'fa fa-files-o',
                routerLink: ['/series'],
                rol: ['SUPER_USUARIO', 'SERIES_DOCUMENTOS_VER']
            },
            {
                label: 'Productos', icon: 'fa fa-cubes',
                items: [
                    {
                        label: 'Articulos',
                        icon: 'fa fa-cube',
                        routerLink: ['/productos'],
                        rol: ['SUPER_USUARIO', 'PRODUCTOS_VER']
                    },
                    {
                        label: 'Preparaciones',
                        icon: 'fa fa-list',
                        routerLink: ['/preparaciones'],
                        rol: ['SUPER_USUARIO', 'PREPARACIONES_VER']
                    },
                    {
                        label: 'Grupos',
                        icon: 'fa fa-cubes',
                        routerLink: ['/grupos'],
                        rol: ['SUPER_USUARIO', 'GRUPOS_VER']
                    },
                    {
                        label: 'Categorías',
                        icon: 'fa fa-folder-open',
                        routerLink: ['/categorias/0'],
                        rol: ['SUPER_USUARIO', 'CATEGORIAS_VER']
                    },
                    {
                        label: 'Marcas',
                        icon: 'fa fa-trademark',
                        routerLink: ['/marcas'],
                        rol: ['SUPER_USUARIO', 'MARCAS_VER']
                    }
                ],
                rol: ['SUPER_USUARIO', 'PRODUCTOS_VER', 'CATEGORIAS_VER', 'MARCAS_VER']
            },
            {
                label: 'Clientes',
                icon: 'fa fa-address-book',
                routerLink: ['/clientes'],
                rol: ['SUPER_USUARIO', 'CLIENTES_VER']
            },
            {
                label: 'Empleados',
                icon: 'fa fa-users',
                items: [
                    {
                        label: 'Ver Empleados',
                        icon: 'fa fa-users',
                        routerLink: ['/empleados'],
                        rol: ['SUPER_USUARIO', 'EMPLEADOS_VER']
                    },
                    {
                        label: 'Asistencia',
                        icon: 'fa fa-calendar-check-o',
                        routerLink: ['/empleados/asistencia'],
                        rol: ['SUPER_USUARIO', 'EMPLEADOS_ASISTENCIA_VER']
                    }
                ],
                rol: ['SUPER_USUARIO', 'EMPLEADOS_VER', 'EMPLEADOS_ASISTENCIA_VER']
            },
            {
                label: 'Proveedores',
                icon: 'fa fa-truck',
                routerLink: ['/proveedores'],
                rol: ['SUPER_USUARIO', 'PROVEEDORES_VER']
            },
            {
                label: 'Impresoras',
                icon: 'fa fa-print',
                routerLink: ['/impresoras'],
                rol: ['SUPER_USUARIO', 'IMPRESORA_VER']
            },
            {
                label: 'Usuarios',
                icon: 'fa fa-users',
                routerLink: ['/usuarios'],
                rol: ['SUPER_USUARIO', 'USUARIOS_VER']
            },
            {
                label: 'Perfiles de usuario',
                icon: 'fa fa-id-card-o',
                routerLink: ['/perfiles'],
                rol: ['SUPER_USUARIO', 'PERFILES_VER']
            }
        ],
        rol: ['SUPER_USUARIO', 'UNIDADES_VER', 'BANCOS_VER', 'CUENTAS_BANCARIAS_VER',
            'PUNTO_VENTA_VER', 'SERIES_DOCUMENTOS_VER', 'PRODUCTOS_VER', 'CATEGORIAS_VER',
            'MARCAS_VER', 'CLIENTES_VER', 'PROVEEDORES_VER', 'USUARIOS_VER', 'PERFILES_VER', 'EMPLEADOS_VER', 'EMPLEADOS_ASISTENCIA_VER']
    },
    {
        label: 'Compras', icon: 'fa fa-archive',
        items: [
            {
                label: 'Ver compras',
                icon: 'fa fa-eye',
                routerLink: ['/compras'],
                rol: ['SUPER_USUARIO', 'COMPRAS_VER']
            },
            {
                label: 'Nueva compra',
                icon: 'fa fa-handshake-o',
                routerLink: ['/compras/nueva-compra'],
                rol: ['SUPER_USUARIO', 'COMPRAS_CREAR']
            },
            {
                label: 'Ordenes de compra', icon: 'fa fa-file-text',
                items: [
                    {
                        label: 'Ver órdenes',
                        icon: 'fa fa-eye',
                        routerLink: ['/compras/ver-ordenes'],
                        rol: ['SUPER_USUARIO', 'ORDEN_COMPRA_VER']
                    },
                    {
                        label: 'Nueva orden',
                        icon: 'fa fa-file-text',
                        routerLink: ['/compras/nueva-orden'],
                        rol: ['SUPER_USUARIO', 'ORDEN_COMPRA_CREAR']
                    }
                ],
                rol: ['SUPER_USUARIO', 'ORDEN_COMPRA_VER', 'ORDEN_COMPRA_CREAR']
            },
        ],
        rol: ['SUPER_USUARIO', 'COMPRAS_VER', 'COMPRAS_CREAR', 'ORDEN_COMPRA_VER', 'ORDEN_COMPRA_CREAR']
    },
    {
        label: 'Ventas', icon: 'fa fa-shopping-cart',
        items: [
            {
                label: 'Ver comprobantes',
                icon: 'fa fa-eye',
                routerLink: ['/ventas/ver-comprobantes'],
                rol: ['SUPER_USUARIO', 'VENTAS_VER']
            },
            {
                label: 'Ver contingencias',
                icon: 'fa fa-eye',
                routerLink: ['/ventas/ver-contingencias'],
                rol: ['SUPER_USUARIO', 'VENTAS_VER']
            },
            {
                label: 'Nueva venta',
                icon: 'fa fa-shopping-basket',
                routerLink: ['/ventas/nueva-venta'],
                rol: ['SUPER_USUARIO', 'VENTAS_CREAR']
            },
            {
                label: 'Nota de Crédito',
                icon: 'fa fa-file-text-o',
                routerLink: ['/ventas/emitir-nota-credito'],
                rol: ['SUPER_USUARIO', 'VENTAS_CREAR']
            },
            {
                label: 'Nota de Débito',
                icon: 'fa fa-file-text-o',
                routerLink: ['/ventas/emitir-nota-debito'],
                rol: ['SUPER_USUARIO', 'VENTAS_CREAR']
            },
            {
                label: 'Guías de remisión', icon: 'fa fa-file',
                items: [
                    {
                        label: 'Ver Guías',
                        icon: 'fa fa-eye',
                        routerLink: ['/guia-remision/ver-guias'],
                        rol: ['SUPER_USUARIO', 'GUIA_REMISION_CREAR']
                    },
                    {
                        label: 'Nueva Guía',
                        icon: 'fa fa-file-text-o',
                        routerLink: ['/guia-remision/emitir-guia'],
                        rol: ['SUPER_USUARIO', 'GUIA_REMISION_CREAR']
                    }
                ],
                rol: ['SUPER_USUARIO', 'GUIA_REMISION_CREAR']
            },
            {
                label: 'Cotizaciones', icon: 'fa fa-file-text',
                items: [
                    {
                        label: 'Ver Cotizaciones',
                        icon: 'fa fa-eye',
                        routerLink: ['/ventas/ver-cotizaciones'],
                        rol: ['SUPER_USUARIO', 'COTIZACION_VER']
                    },
                    {
                        label: 'Nueva Cotización',
                        icon: 'fa fa-file-text',
                        routerLink: ['/ventas/nueva-cotizacion'],
                        rol: ['SUPER_USUARIO', 'COTIZACION_VER']
                    }
                ],
                rol: ['SUPER_USUARIO', 'COTIZACION_VER']
            },
            {
                label: 'Despacho',
                icon: 'fa fa-external-link',
                routerLink: ['/ventas/despacho'],
                rol: ['SUPER_USUARIO', 'VENTAS_DESPACHO_VER']
            },
        ],
        rol: ['SUPER_USUARIO', 'COTIZACION_VER', 'GUIA_REMISION_CREAR', 'VENTAS_VER', 'VENTAS_CREAR']
    },


    {
        label: 'Restaurante', icon: 'fa fa-cutlery',
        items: [
            {
                label: 'Comandero',
                icon: 'fa fa-clipboard',
                routerLink: ['/restaurante'],
                rol: ['SUPER_USUARIO', 'RESTAURANTE_COMANDAS_VER']
            },
            {
                label: 'Pedidos',
                icon: 'fa fa-laptop',
                routerLink: ['/restaurante/pedidos'],
                rol: ['SUPER_USUARIO', 'RESTAURANTE_PEDIDOS_VER']
            },
            {
                label: 'Cocina',
                icon: 'fa fa-hourglass-start',
                routerLink: ['/restaurante/cocina'],
                rol: ['SUPER_USUARIO', 'RESTAURANTE_COCINA_VER']
            },
            {
                label: 'Reparto',
                icon: 'fa fa-user-circle',
                routerLink: ['/restaurante/reparto'],
                rol: ['SUPER_USUARIO', 'REPARTO_VER']
            },
            {
                label: 'Mantenimientos',
                icon: 'fa fa-wrench',
                items: [
                    {
                        label: 'Salones',
                        icon: 'fa fa-building-o',
                        routerLink: ['/restaurante/salones'],
                        rol: ['SUPER_USUARIO', 'RESTAURANTE_SALONES_VER']
                    },
                    {
                        label: 'Mesas',
                        icon: 'fa fa-tablet',
                        routerLink: ['/restaurante/mesas'],
                        rol: ['SUPER_USUARIO', 'RESTAURANTE_MESAS_VER']
                    },
                    {
                        label: 'Area de producción',
                        icon: 'fa fa-industry',
                        routerLink: ['/restaurante/zonas-produccion'],
                        rol: ['SUPER_USUARIO', 'RESTAURANTE_ZONAS_VER']
                    }
                ],
                rol: ['SUPER_USUARIO', 'RESTAURANTE_MESAS_VER', 'RESTAURANTE_SALONES_VER', 'RESTAURANTE_ZONAS_VER']
            }
        ],
        rol: ['SUPER_USUARIO', 'RESTAURANTE_COMANDAS_VER', 'RESTAURANTE_MESAS_VER', 'RESTAURANTE_SALONES_VER', 'REPARTO_VER']
    },
    {
        label: 'Operaciones',
        icon: 'fa fa-suitcase',
        items: [
            {
                label: 'Caja',
                icon: 'fa fa-money',
                routerLink: ['/caja'],
                rol: ['SUPER_USUARIO', 'CAJA_VER']
            },
            {
                label: 'Reporte de cajas',
                icon: 'fa fa-book',
                routerLink: ['/caja/reporte'],
                rol: ['SUPER_USUARIO', 'CAJA_VER_HISTORIAL']
            },
            {
                label: 'Membresias',
                icon: 'fa fa-meetup',
                routerLink: ['/membresias'],
                rol: ['SUPER_USUARIO', 'MEMBRESIAS_VER']
            },
            {
                label: 'Cuentas por cobrar',
                icon: 'fa fa-bookmark',
                routerLink: ['/cuentas-por-cobrar'],
                rol: ['SUPER_USUARIO', 'CUENTAS_POR_COBRAR_VER']
            },
            {
                label: 'Cuentas por Pagar',
                icon: 'fa fa-bookmark',
                routerLink: ['/cuentas-por-cobrar/pagar'],
                rol: ['SUPER_USUARIO', 'CUENTAS_POR_PAGAR_VER']
            },
            {
                label: 'Facturación Electrónica', icon: 'fa fa-file-pdf-o',
                items: [
                    {
                        label: 'Resumen diario',
                        icon: 'fa fa-file-o',
                        routerLink: ['/facturacion-electronica/resumen-diario/ver-resumenes-diarios'],
                        rol: ['SUPER_USUARIO', 'RESUMEN_DIARIO_VER']
                    },
                    {
                        label: 'Comunicación de baja',
                        icon: 'fa fa-file-text',
                        routerLink: ['/facturacion-electronica/comunicacion-baja/ver-comunicaciones-baja'],
                        rol: ['SUPER_USUARIO', 'COMUNICACION_BAJA_VER']
                    }
                ],
                rol: ['SUPER_USUARIO', 'RESUMEN_DIARIO_VER', 'COMUNICACION_BAJA_VER']
            },
            {
                label: 'Inventario',
                icon: 'fa fa-database',
                rol: ['SUPER_USUARIO', 'INVENTARIO_VER', 'INVENTARIO_AJUSTAR', 'INVENTARIO_TRASLADAR'],
                items: [
                    {
                        label: 'Inventario de productos',
                        icon: 'fa fa-database',
                        routerLink: ['/inventario'],
                        rol: ['SUPER_USUARIO', 'INVENTARIO_VER']
                    },
                    {
                        label: 'Lotes y series',
                        icon: 'fa fa-file-text-o',
                        routerLink: ['/inventario/lotes'],
                        rol: ['SUPER_USUARIO', 'INVENTARIO_VER']
                    },
                    {
                        label: 'Ajustes de inventario',
                        icon: 'fa fa-cog',
                        routerLink: ['/inventario-ajustes'],
                        rol: ['SUPER_USUARIO', 'INVENTARIO_AJUSTAR']
                    },
                    {
                        label: 'Traslados de inventario',
                        icon: 'fa fa-exchange',
                        routerLink: ['/inventario-traslados'],
                        rol: ['SUPER_USUARIO', 'INVENTARIO_TRASLADAR']
                    }
                ],
            },
        ],
        rol: ['SUPER_USUARIO', 'CAJA_VER', 'CAJA_VER_HISTORIAL', 'CUENTAS_POR_COBRAR_VER', 'RESUMEN_DIARIO_VER', 'COMUNICACION_BAJA_VER']
    },
    {
        label: 'Reportes',
        icon: 'fa fa-file-text',
        items: [
            {
                label: 'Reporte de ventas',
                icon: 'fa fa-file-archive-o',
                routerLink: ['/reportes/reporte-ventas'],
                rol: ['SUPER_USUARIO', 'REPORTE_VENTAS']
            },
            {
                label: 'Reporte de movimientos',
                icon: 'fa fa-file-excel-o',
                routerLink: ['/reportes/reporte-movimientos'],
                rol: ['SUPER_USUARIO', 'REPORTE_MOVIMIENTOS']
            }
        ],
        rol: ['SUPER_USUARIO', 'REPORTE_VENTAS']
    }
];

export const menuShortcuts = [
    {
        label: 'Ver comprobantes',
        value: {
            nombre: 'Ver comprobantes',
            icono: 'fa fa-shopping-basket',
            ruta: '/ventas/ver-comprobantes',
            rol: ['SUPER_USUARIO', 'VENTAS_VER']
        }
    },
    {
        label: 'Articulos',
        value: {
            nombre: 'Articulos',
            icono: 'fa fa-barcode',
            ruta: '/productos',
            rol: ['SUPER_USUARIO', 'PRODUCTOS_VER']
        }
    },
    {
        label: 'Empleados',
        value: {
            nombre: 'Empleados',
            icono: 'fa fa-users',
            ruta: '/empleados',
            rol: ['SUPER_USUARIO', 'EMPLEADOS_VER']
        },
    },
    {
        label: 'Usuarios',
        value: {
            nombre: 'Usuarios',
            icono: 'pi pi-star',
            ruta: '/usuarios',
            rol: ['SUPER_USUARIO', 'USUARIOS_VER']
        }
    },
    {
        label: 'Nueva venta',
        value: {
            nombre: 'Nueva venta',
            icono: 'fa fa-cart-plus',
            ruta: '/ventas/nueva-venta',
            rol: ['SUPER_USUARIO', 'VENTAS_CREAR']
        },
    },
    {
        label: 'Nueva Cotización',
        value: {
            nombre: 'Nueva Cotización',
            icono: 'fa fa-file-text-o',
            ruta: '/ventas/nueva-cotizacion',
            rol: ['SUPER_USUARIO', 'COTIZACION_VER']
        },
    },
    {
        label: 'Caja',
        value: {
            nombre: 'Caja',
            icono: 'fa fa-money',
            ruta: '/caja',
            rol: ['SUPER_USUARIO', 'CAJA_VER']
        },
    },
    {
        label: 'Cuentas por cobrar',
        value: {
            nombre: 'Cuentas por cobrar',
            icono: 'fa fa-list-ul',
            ruta: '/cuentas-por-cobrar',
            rol: ['SUPER_USUARIO', 'CUENTAS_POR_COBRAR_VER']
        }
    },
    {
        label: 'Restaurante Comandero',
        value: {
            nombre: 'Restaurante Comandero',
            icono: 'fa fa-list-ul',
            ruta: '/restaurante/comandero',
            rol: ['SUPER_USUARIO', 'CUENTAS_POR_COBRAR_VER']
        }
    }
];
