import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Page} from '../domain/page';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {User} from '../domain/user';
import {getBaseUrl} from './util';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(idEmpresa, params: string, modeRoot: boolean): Observable<Page<User>> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<Page<User>>(`${baseUrl}/users?${params}`);
    }

    findAll(idEmpresa, modeRoot: boolean): Observable<any[]> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<any[]>(`${baseUrl}/users/all`);
    }

    findById(idEmpresa, id: number, modeRoot: boolean): Observable<User> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<User>(`${baseUrl}/users/${id}`);
    }

    save(idEmpresa, model: User, modeRoot: boolean): Observable<User> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.post<User>(`${baseUrl}/users`, model);
    }

    update(idEmpresa, model: User, modeRoot: boolean): Observable<User> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.put<User>(`${baseUrl}/users`, model);
    }

    restorePassword(idEmpresa, id, modeRoot: boolean): Observable<User> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.patch<User>(`${baseUrl}/users/${id}/restore-password`, {});
    }

    deleteById(idEmpresa, id: number, modeRoot: boolean): Observable<void> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.delete<void>(`${baseUrl}/users/${id}`);
    }

    generateToken(idEmpresa: number): Observable<string> {
        const baseUrl = getBaseUrl(idEmpresa, true);
        return this.http.get<string>(`${baseUrl}/token?tipoBusqueda=ID`);
    }

    roles(): Observable<string[]> {
        return this.http.get<string[]>(`${this.baseUrl}/auth/account/roles`);
    }

    me(): Observable<User> {
        return this.http.get<User>(`${this.baseUrl}/auth/account`);
    }

    saveMe(user): Observable<User> {
        return this.http.put<User>(`${this.baseUrl}/auth/account`, user);
    }

    changePwd(changePwd): Observable<boolean> {
        return this.http.patch<boolean>(`${this.baseUrl}/auth/account/change-password`, changePwd);
    }

    uploadImage(idEmpresa, formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/files/companies/${idEmpresa}/imagenes`, formData);
    }

}
