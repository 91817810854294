import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderRequestService} from '@app/service/loader-request.service';
import {LoaderState} from '@app/domain/loader-state';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
    show = false;
    private subscription: Subscription;
    constructor(private loaderService: LoaderRequestService,
                private ngxService: NgxUiLoaderService) { }
    ngOnInit() {
        this.subscription = this.loaderService.loaderState
            .subscribe((state: LoaderState) => {
                if (state.show) {
                    if (state.background) {
                        this.ngxService.startBackground();
                    } else {
                        this.ngxService.start();
                    }
                } else {
                    if (state.background) {
                        this.ngxService.stopBackground();
                    } else {
                        this.ngxService.stop();
                    }
                }
            });
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
