import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {getBaseUrl} from './util';
import {ShortcutMenu} from '@app/domain/shortcut-menu';

@Injectable({
    providedIn: 'root'
})
export class ShortcutService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(idEmpresa, modeRoot: boolean): Observable<ShortcutMenu[]> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<ShortcutMenu[]>(`${baseUrl}/shortcuts`);
    }

    findById(idEmpresa, id: number, modeRoot: boolean): Observable<ShortcutMenu> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<ShortcutMenu>(`${baseUrl}/shortcuts/${id}`);
    }

    save(idEmpresa, model: ShortcutMenu, modeRoot: boolean): Observable<ShortcutMenu> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.post<ShortcutMenu>(`${baseUrl}/shortcuts`, model);
    }

    deleteById(idEmpresa, id: number, modeRoot: boolean): Observable<void> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.delete<void>(`${baseUrl}/shortcuts/${id}`);
    }

}
