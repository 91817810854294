import {State} from '@ngxs/store';
import {Injectable} from '@angular/core';

@Injectable()
@State({
    name: 'productFilters',
    defaults: {
        productForm: {
            model: undefined,
            dirty: false,
            status: '',
            errors: {}
        }
    }
})
export class FiltersProductState {
}
