// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {Product} from '@app/domain/product';
import {Office} from '@app/domain/office';
import {ProductPrice} from '@app/domain/product-price';
import * as moment from 'moment';
import {Customer} from '@app/domain/customer';
import {Group} from '@app/domain/group';
import {CommandDetailGroupOption} from '@app/domain/restaurante/command-detail-group-option';
import * as printJS from 'print-js';
import {CashRegisterDetail} from '@app/domain/cash-register-detail';
import {PaymentDetail} from '@app/domain/payment-detail';
import {TicketFee} from '@app/domain/ventas/ticket-fee';
import {Observable} from 'rxjs';
import {Ticket} from '@app/domain/ventas/ticket';
import {Quotation} from '@app/domain/ventas/quotation';

export const utilFunctions = {
    production: false,
    endpointApi: 'http://localhost:8080/api'
};


export const openUrlInNewTab = (url) => {
    const win = window.open(url, '_blank');
    win.focus();
};

export const openTab = (url) => {
    // Create link in memory
    const a = window.document.createElement('a');
    a.target = '_blank';
    a.href = url;

    // Dispatch fake click
    const e = window.document.createEvent('MouseEvents');
    e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    a.dispatchEvent(e);
};


export const isMobileDevice = (): boolean => {
    return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1);
};


export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const dsplit = reader.result.toString().split(',');
        resolve(dsplit[1]);
    };
    reader.onerror = error => reject(error);
});


export const markFormGroupTouched = (formGroup: UntypedFormGroup) => {
    (Object as any).values(formGroup.controls).forEach(control => {
        control.markAsTouched();
        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
};


export const getFormValidationErrors = (form: UntypedFormGroup) => {
    const result = [];
    Object.keys(form.controls).forEach(key => {

        const controlErrors: ValidationErrors = form.get(key).errors;
        if (controlErrors) {
            Object.keys(controlErrors).forEach(keyError => {
                result.push({
                    control: key,
                    error: keyError,
                    value: controlErrors[keyError]
                });
            });
        }
    });
    return result;
};

export const getBreadcrumb = (route: any) => {
    return route.snapshot.data.breadcrumb;
};

export const numberNot = (num) => {
    return (control: AbstractControl): {[key: string]: any} => {
        const p = control.value;
        // tslint:disable-next-line:triple-equals
        if (p != num) {
            return null;
        } else {
            return { numberNot: true};
        }
    };
};

export const ValidateUbigeo = (control: AbstractControl) => {

    if (!control.value) {
        return null;
    }

    if (typeof (control.value) !== 'object') {
        return {ubigeo: true};
    } else {
        if (control.value.codigoCiudad) {
            return null;
        } else {
            return {ubigeo: true};
        }
    }
};


export const ValidateIsNumber = (control: AbstractControl) => {
    if (!control.value) {
        return null;
    }
    if (isNaN(control.value)) {
        return {numeric: true};
    } else {
        return null;
    }
};

export const ValidateProduct = (control: AbstractControl) => {
    if (!control.value || !control.value.id) {
        return {productRequired: true};
    } else {
        return null;
    }
};

export const totalFormaPagoMovimiento = (amount: number) => {
    return (control: AbstractControl): {[key: string]: any} => {
        const p = control.value as CashRegisterDetail;
        if (!control.value) {
            return null;
        }
        if (toNumber(p.pagos.map(pa => pa.monto).reduce(add, 0)) === toNumber(amount)) {
            return null;
        } else {
            return { totalFormaPago: true};
        }
    };
};

export const totalFormaPago = (amount: number) => {
    return (control: AbstractControl): {[key: string]: any} => {
        const p = control.value as PaymentDetail[];
        if (!control.value) {
            return null;
        }
        if (toNumber(p.map(pa => pa.monto).reduce(add, 0)) === toNumber(amount)) {
            return null;
        } else {
            return { totalFormaPago: true};
        }
    };
};

export const totalCuotas = (amount: number) => {
    return (control: AbstractControl): {[key: string]: any} => {
        console.log('Validacion cuotas');
        const p = control.value as TicketFee[];
        if (!control.value) {
            return null;
        }
        if (toNumber(p.map(pa => pa.monto).reduce(add, 0)) <= toNumber(amount) && !p.filter(pa => !pa.monto || !pa.fecha).length) {
            console.log('Validacion ok');
            return null;
        } else {
            console.log('Validacion no ok');
            return { totalCuotas: true};
        }
    };
};

export const validateDocumentObject = (typeDocument) => {
    return (control: AbstractControl): {[key: string]: any} => {
        console.log('Validacion numero de documento en objeto');
        const p = control.value as Customer;
        if (!control.value || typeof control.value !== 'object') {
            return null;
        }
        if (typeDocument === '1' && p.numeroDocumento.length !== 8) {
            return { documentLength: true};
        } else if (typeDocument === '6' && p.numeroDocumento.length !== 11) {
            return { documentLength: true};
        } else {
            return null;
        }
    };
};

export const ValidateCustomer = (tipoComprobante: string) => {
    return (control: AbstractControl): {[key: string]: any} => {
        const customer = control.value as Customer;
        if (!control.value) {
            return null;
        }
        if (tipoComprobante === '01' && customer.tipoDocumento !== '6') {
            return { requiredRuc: true};
        } else if (tipoComprobante === '03' && customer.tipoDocumento !== '1') {
            return { requiredDni: true};
        } else if (tipoComprobante === '01' && !customer.direccionCompleta) {
            return { requiredAddress: true};
        } else {
            return null;
        }
    };
};


export const minLengthArray = (min: number) => {
    return (c: AbstractControl): {[key: string]: any} => {
        if (c.value.length >= min) {
            return null;
        }

        return { minLengthArray: {valid: false }};
    };
};

export const validateDocumentNumber = (numberValidate: string) => {
    return (c: AbstractControl): {[key: string]: any} => {
        console.log('Validando num doc cli', c.value, numberValidate);
        if (c.value === numberValidate) {
            return null;
        }
        return { invalidDocument: {valid: false }};
    };
};

export const qtyLotes = (qty: number) => {
    console.log('qty', qty);
    return (c: AbstractControl): {[key: string]: any} => {
        if (Number(c.value.map(v => v.cantidad).reduce(add, 0)) === Number(qty)) {
            return null;
        }
        return { qtyLotes: {valid: false }};
    };
};

export const qtyLotesTransfer = (qty: number) => {
    return (c: AbstractControl): {[key: string]: any} => {
        console.log('Validando lotes', c.value, qty);
        if (Number(c.value.map(v => v.cantidadAtencion).reduce(add, 0)) === Number(qty)) {
            return null;
        }
        console.log('No cumple con la cantidad');
        return { qtyLotesTransfer: {valid: false }};
    };
};

export const ValidatePrecioVenta = (control: AbstractControl) => {
    if (!control.value) {
        return null;
    }
    if (control.value.precio === undefined || control.value.precio === null) {
        return {precio: true};
    }
    if (!control.value.tipoPrecio) {
        return {tipoPrecio: true};
    }

    if (control.value.tipoPrecio === 'MAYOREO' && !control.value.unidadesMayoreo) {
        return {unidadesMayoreo: true};
    }

    if (control.value.tipoPrecio === 'ESPECIAL' && !control.value.nombre) {
        return {nombre: true};
    }

    return null;
};

export const ValidateGroupProduct = (group: Group) => {
    return (c: AbstractControl): {[key: string]: any} => {
        if ((!c.value || c.value.length === 0) && (!group.requerido)) {
            return null;
        }
        const opts = c.value as CommandDetailGroupOption[];
        if (group.requerido) {
            const forzarMinimo = group.forzarMinimo;
            const forzarMaximo = group.forzarMaximo;
            const nOpciones = opts
                .filter((op) => op.idGrupo === group.id)
                .map((op) => op.cantidad)
                .reduce((a, b) => a + b, 0);
            if (forzarMinimo === forzarMaximo && nOpciones !== forzarMaximo) {
                return { groupValidation: {valid: false, message: `${group.nombre}: Debe seleccionar ${forzarMaximo} opciones.` }};
            } else if (
                forzarMinimo !== forzarMaximo &&
                !(nOpciones >= forzarMinimo && nOpciones <= forzarMaximo)
            ) {
                return { groupValidation: {valid: false, message: `${group.nombre}: Debe seleccionar entre ${forzarMinimo} y ${forzarMaximo} opciones.` }};
            }
        } else {
            return null;
        }
        return undefined;
    };
};


export const toNumber = (num: any, decimals?: number) => {
    if (!decimals) {
        decimals = 2;
    }
    return Number(Number(num).toFixed(decimals));
};

export const getTextEmailFromTicket = (ticket: Ticket): string => {
    const transformTipoComprobante = (value: any): any => {
        switch (value) {
            case '01':
                return 'Factura';
            case '03':
                return 'Boleta';
            case 'NV':
                return 'Nota de venta';
            case '07':
                return 'Nota de crédito';
            case '08':
                return 'Nota de débito';
            case '09':
                return 'Guía de remisión electrónica';
            case 'GM':
                return 'Guía de remisión manual';
            case 'CO':
                return 'Cotización';
            default:
                return '';
        }
    };
    return `<h2>Comprobante Electrónico.</h2><br>
<p>Estimado:&nbsp;&nbsp;<strong>${ticket.denominacionReceptor}</strong>&nbsp;&nbsp;</p>
<p>se&nbsp;<strong>adjunta</strong>&nbsp;el comprobante electrónico&nbsp;
<strong>"${transformTipoComprobante(ticket.tipoComprobante)}"</strong>&nbsp;de Serie: ${ticket.serie} y
&nbsp;Número: ${ticket.numero};&nbsp;en formato&nbsp;<strong>PDF</strong>&nbsp;y&nbsp;
<strong>XML</strong>.</p><p><br></p>`;
};


export const getTextEmailFromQuotation = (q: Quotation) => {
   return `<h3>Cotización electrónica</h3><br>
<p>Estimado:&nbsp;&nbsp;<strong>${q.denominacionReceptor}</strong>
&nbsp;&nbsp;</p><p>se&nbsp;<strong>adjunta</strong>&nbsp;la cotización solicitada con fecha&nbsp;
<strong>${q.fechaEmision}</strong>;&nbsp;en formato&nbsp;<strong>PDF</strong>.</p><p><br></p>`;
};


export const formatNumberWhatsapp = (num: any): string => {
    if (!num) {
        return '';
    }
    let numberW = num.replace(/\s+/g, '');
    if (!numberW.startsWith('+')) {
        numberW = '+51' + numberW;
    }
    return numberW;
};



const wholesale = (arr: ProductPrice[], target: number): ProductPrice => {
    arr = arr.filter(pv => pv.unidadesMayoreo !== undefined);
    let start = 0;
    let end = arr.length - 1;
    let ans = -1;
    while (start <= end) {
        const mid = parseInt(((start + end) / 2).toString(), 10);
        // Move to the left side if the target is smaller
        if (arr[mid].unidadesMayoreo > target) {
            end = mid - 1;
        } else {
            ans = mid;
            start = mid + 1;
        }
    }
    return ans >= 0 ? arr[ans] : undefined;
};


export const getPriceProduct = (product: Product, puntoVenta: Office, ops: any): number => {
    if (!ops) {
        ops = {};
    }
    if (!product.preciosVenta || !product.preciosVenta.length) {
        return 0;
    } else {
            const listPrices = product.preciosVenta.filter(pv =>
                (product.preciosPorPuntoVenta
                && pv.puntoVenta
                && pv.puntoVenta.id === puntoVenta.id)
                ||
                (!product.preciosPorPuntoVenta &&  !pv.puntoVenta)
            );
            const igv = ops.igv;
            // Mayoreo
            if (ops.qty) {
                const wholesalePrice = wholesale(listPrices, ops.qty);
                if (wholesalePrice) {
                    if (!product.igv) {
                        return wholesalePrice.precio * (1 + igv);
                    } else {
                        return wholesalePrice.precio;
                    }
                }
            }
           // Default
            let defaultPrice = listPrices.find(pv => pv.tipoPrecio === 'POR_DEFECTO') ? listPrices.find(pv => pv.tipoPrecio === 'POR_DEFECTO').precio : 0;
            if (!product.igv) {
                defaultPrice = defaultPrice * (1 + igv);
            }
            if (ops.porcentajeRecargoPorItem && !ops.absolute) {
                defaultPrice = (defaultPrice / (1 + igv + (ops.porcentajeRecargoPorItem / 100))) * (1 + igv);
                console.log('Precio tiene recargo en el producto', defaultPrice);
            }
            return defaultPrice;
    }
};

export const promiseBlob2Data = (blob): Observable<any> => {
    return new Observable<any>(observer => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data: string | ArrayBuffer = reader.result;
            const base64 = base64data.toString().replace('data:application/pdf;base64,', '');
            const objectUrl = window.URL.createObjectURL(new Blob([blob], {type: 'application/pdf'}));
            observer.next({
                base64,
                objectUrl
            });
            observer.complete();
        };
    });
};

export const addDaysToDate = (date, days): Date => {
    const res = new Date(date);
    res.setDate(res.getDate() + days);
    return res;
};

export const add = (a, b) => Number(a) + Number(b);

export const filterProductPrice = (pp: ProductPrice, preciosPorPuntoVenta: boolean) => {
    if (!preciosPorPuntoVenta) {
        return !pp.puntoVenta;
    } else {
        return pp.puntoVenta;
    }
};

export const getDateToString = (date: Date) => {
    let hour: any  = date.getHours();
    let minute: any  = date.getMinutes();
    hour = hour < 10 ? `0${hour}` : hour;
    minute = minute < 10 ? `0${minute}` : minute;

    return `${hour}:${minute}`;
};


export const getStringToDate = (date: string) => {
    const timeArr = date.split(':');
    const d = new Date();
    d.setMinutes(parseInt(timeArr[1], 10));
    d.setHours(parseInt(timeArr[0], 10));
    return d;
};

export const isScrolledIntoView = (elementId: string): boolean => {
    const element = document.getElementById(elementId);
    const w = window;
    const d = document;
    const e = d.documentElement;
    const g = d.getElementsByTagName('body')[0];
    const windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;

    const docViewTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    const docViewBottom = docViewTop + windowHeight;

    const rect = element.getBoundingClientRect();
    const offsetElement = {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX,
        height: rect.height
    };
    const elemTop = offsetElement.top;
    const elemHeight = offsetElement.height;
    const elemBottom = elemTop + elemHeight;

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
};

export const displayTimeElapsedFromMs = (fechaInicial, fechaFinal?): string => {
    const orderDateTime = new Date(fechaInicial).getTime();
    let nowTime;
    if (fechaFinal) {
        nowTime = fechaFinal;
    } else {
        nowTime = new Date().getTime();
    }
    const ms = nowTime - orderDateTime;
    const diff = moment.duration(ms, 'milliseconds');
    const aresp = [];
    const pad = (n) => n < 10 ? '0' + n : n;
    const d = parseInt(diff.asDays().toString(), 10);
    const h = Math.floor(diff.asHours()) % 24;
    const m = Math.floor(diff.asMinutes()) % 60;
    const s = Math.floor(diff.asSeconds()) % 60;

    if (d) {
        aresp.push(Math.floor(diff.asHours()) % 24);
        aresp.push(Math.floor(diff.asMinutes()) % 60);
        aresp.push(Math.floor(diff.asSeconds()) % 60);
        return `${d}d ${aresp.map(pad).join(':')}`;
    } else if (!d && h) {
        aresp.push(Math.floor(diff.asHours()) % 24);
        aresp.push(Math.floor(diff.asMinutes()) % 60);
        aresp.push(Math.floor(diff.asSeconds()) % 60);
        return `${aresp.map(pad).join(':')}`;
    } else if (!d && !h && m) {
        return `${m} min.`;
    } else if (!d && !h && !m && s) {
        return `${s} seg.`;
    }
    return '';
};


const colors = [
    '#FF6384',
    '#36A2EB',
    '#FFCE56',
    '#81162f',
    '#0c17ff',
    '#836211',
    '#6eae2f',
    '#fd8439',
    '#008f72',
    '#7b45ff',
    '#3a8300',
    '#f86300',
    '#00236b'
];

export const getRandomArbitrary = (min, max) => {
    return Math.trunc(Math.random() * (max - min) + min);
};
export const fnFindColor = (i) => {
    return colors[i] ? colors[i] : colors[getRandomArbitrary(0, colors.length)];
};

export const printPdfDirectInBrowser = (url: string) => {
    printJS(url);
};
