

export enum MotivoTraslado {
    VENTA = '01',
    VENTA_CONFIRMACION_COMPRADOR = '14',
    COMPRA = '02',
    TRASLADO_ESTABLECIMIENTOS_MISMA_EMPRESA = '04',
    TRASLADO_EMISOR_ITINERANTE_CP = '18',
    IMPORTACION = '08',
    EXPORTACION = '09',
    TRASLADO_ZONA_PRIMARIA = '19',
    OTROS = '13'
}

export enum TipoTransporte {
    TRANSPORTE_PUBLICO = '01',
    TRANSPORTE_PRIVADO = '02'
}

export  enum TipoGuia {
    ELECTRONICA = 'ELECTRONICA',
    MANUAL = 'MANUAL',
}

export enum DocumentosRelacionados {
    NUMERACION_DAM = '01',
    NUMERO_ORDEN_ENTREGA = '02',
    NUMERO_SCOP = '03',
    NUMERO_MANIFIESTO_CARGA = '04',
    NUMERO_CONSTANCIA_DETRACCION = '05',
    OTROS = '06'
}
