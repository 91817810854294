import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {MessageService} from 'primeng/api';
import {PrintEscPos} from '@app/domain/print-esc-pos';
import {PrintPdf} from '@app/domain/print-pdf';


@Injectable({
    providedIn: 'root'
})
export class PrintService {

    private wsEndpoint: string = environment.wsEndpointPrint;

    constructor(private http: HttpClient,
                private messageService: MessageService
            ) {
    }

    printCoffe(data) {
        const options = {
            headers: {hideLoader: 'S', isPrinting: 'S'}
        };
        this.http.post<any>(`${this.wsEndpoint}/print-coffe`, data, options).subscribe(resp => {
            if (resp.printed) {
                this.messageService.add({severity: 'success', summary: data.printerName, detail: resp.message});
            } else {
                this.messageService.add({severity: 'warn', summary: data.printerName, detail: 'Error al imprimir: ' + resp.message});
            }
        }, error => {
            this.messageService.add({severity: 'error', summary: data.printerName, detail: 'Ocurrió un error al imprimir'});
        });
    }

    printCoffeLocalEscPos(baseUrl, data: PrintEscPos) {
        console.log('Orden de impresion EscPos', baseUrl, data);
        this.http.post<any>(`${baseUrl}/api/print-escpos`, data).subscribe(resp => {
            this.messageService.add({severity: 'success', summary: data.printerName, detail: 'Impreso correctamente'});
        }, error => {
            this.messageService.add({severity: 'error', summary: data.printerName, detail: 'Ocurrió un error al imprimir'});
        });
    }

    printCoffeLocalPdf(baseUrl, data: PrintPdf) {
        console.log('Orden de impresion Pdf', baseUrl, data);
        this.http.post<any>(`${baseUrl}/api/print-pdf`, data).subscribe(resp => {
            this.messageService.add({severity: 'success', summary: data.printerName, detail: 'Impreso correctamente'});
        }, error => {
            this.messageService.add({severity: 'error', summary: data.printerName, detail: 'Ocurrió un error al imprimir'});
        });
    }
}
