import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Page} from '@app/domain/page';
import {Membership} from '@app/domain/membership';
import {ContractPlan} from '@app/domain/contract-plan';
import {DatePipe} from '@angular/common';
import {TipoDocumentoPipe} from '@app/pipe/tipo-documento.pipe';



@Injectable({
    providedIn: 'root'
})
export class MembershipService {

    private baseUrl: string = environment.endpointApi;
    private wsEndpoint: string = environment.wsEndpoint;

    constructor(private http: HttpClient, private datePipe: DatePipe,
                private tipoDocumentoPipe: TipoDocumentoPipe) {
    }

    findById(id): Observable<Membership> {
        return this.http.get<Membership>(`${this.baseUrl}/memberships/${id}`);
    }

    findAllPage(params: string): Observable<Page<Membership>> {
        return this.http.get<any>(`${this.baseUrl}/memberships?${params}`);
    }

    delete(id): Observable<void> {
        return this.http.delete<any>(`${this.baseUrl}/memberships/detail/${id}`);
    }

    updateStatus(id, membership: Membership): Observable<void> {
        return this.http.patch<void>(`${this.baseUrl}/memberships/${id}/estado`, membership);
    }

    downloadContract(membership: Membership, contract: ContractPlan) {
        const data = {
            fechaInicio: this.datePipe.transform(membership.fechaInicio, 'dd-MM-yyyy'),
            fechaFin: this.datePipe.transform(membership.fechaFin, 'dd-MM-yyyy'),
            comprobante: `${membership.detalleComprobante.ticket.serie}-${membership.detalleComprobante.ticket.numero}`,
            tipoComprobante: this.tipoDocumentoPipe.transform(membership.detalleComprobante.ticket.tipoComprobante),
            totalVenta: membership.detalleComprobante.ticket.totalVenta.toFixed(2),
            codigoMoneda: membership.detalleComprobante.ticket.codigoMoneda,
            clienteNombre: membership.cliente.razonSocial,
            clienteDireccion: membership.cliente.direccionCompleta,
            clienteDocumento: membership.cliente.numeroDocumento,
            clienteGiro: membership.cliente.giro,
            planNombre: membership.plan.nombre,
            planDetalle: membership.plan.detalle
        };
        membership.detalleComprobante.ticket.camposAdicionales.forEach(ca => {
            const key = ca.nombreCampo.replace(/\s+/g, '').toLowerCase();
            data[key] = ca.valorCampo;
        });
        const body = {
            url: contract.url,
            data
        };
        this.http.post(`${this.wsEndpoint}/contract-builder`, body, {responseType: 'blob'}).subscribe(resp => {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const blob = new Blob([resp], {type: 'octet/stream'});
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = contract.nombre + '.docx';
            a.click();
            window.URL.revokeObjectURL(url);
        });
    }
}
