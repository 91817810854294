import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {OpeningHour} from '@app/domain/opening-hour';


@Injectable({
    providedIn: 'root'
})
export class OpeningHourService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<OpeningHour[]> {
        return this.http.get<OpeningHour[]>(`${this.baseUrl}/opening-hours?${params}`);
    }

    findById(id): Observable<OpeningHour> {
        return this.http.get<OpeningHour>(`${this.baseUrl}/opening-hours/${id}`);
    }

    save(model: OpeningHour): Observable<OpeningHour> {
        return this.http.post<OpeningHour>(`${this.baseUrl}/opening-hours`, model);
    }

    update(id, model: OpeningHour): Observable<OpeningHour> {
        return this.http.put<OpeningHour>(`${this.baseUrl}/opening-hours/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/opening-hours/${id}`);
    }
}
