import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Page} from '@app/domain/page';
import {InventoryTransfer} from '@app/domain/inventory-transfer';
import {InventoryTransferAction} from '@app/domain/inventory-transfer-action';

@Injectable({
    providedIn: 'root'
})
export class InventoryTransferService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAll(params): Observable<Page<InventoryTransfer>> {
        return this.http.get<Page<InventoryTransfer>>(`${this.baseUrl}/inventory-transfers?${params}`);
    }

    findById(id): Observable<InventoryTransfer> {
        return this.http.get<InventoryTransfer>(`${this.baseUrl}/inventory-transfers/${id}`);
    }

    save(model: InventoryTransfer): Observable<InventoryTransfer> {
        return this.http.post<InventoryTransfer>(`${this.baseUrl}/inventory-transfers`, model);
    }

    update(id, model: InventoryTransfer): Observable<InventoryTransfer> {
        return this.http.put<InventoryTransfer>(`${this.baseUrl}/inventory-transfers/${id}`, model);
    }

    delete(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/inventory-transfers/${id}`);
    }

    send(id, model: InventoryTransferAction): Observable<InventoryTransfer> {
        return this.http.post<InventoryTransfer>(`${this.baseUrl}/inventory-transfers/${id}/send`, model);
    }

    receive(id, model: InventoryTransferAction): Observable<InventoryTransfer> {
        return this.http.post<InventoryTransfer>(`${this.baseUrl}/inventory-transfers/${id}/receive`, model);
    }

}
