import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';
import {Quotation} from '../domain/ventas/quotation';
import {DomSanitizer} from '@angular/platform-browser';
import {promiseBlob2Data} from '@app/utils/util-functions';
import {WhatsappService} from '@app/service/whatsapp.service';
import {LoginService} from '@app/service/login.service';


@Injectable({
    providedIn: 'root'
})
export class QuotationService {

    private baseUrl: string = environment.endpointApi;


    constructor(private http: HttpClient, private sanitizer: DomSanitizer,
                private whatsappService: WhatsappService,
                private loginService: LoginService) {
    }

    findAllPage(params: string): Observable<any> {
        console.log('findAllPage Quotations');
        return this.http.get<any>(`${this.baseUrl}/quotation?${params}`);
    }

    save(model: Quotation): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/quotation`, model);
    }

    update(model: Quotation): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}/quotation`, model);
    }

    getNextNumber(params): Observable<any> {
        return this.http.get(`${this.baseUrl}/quotation/next-number?${params}`);
    }

    openPdf(quotation: Quotation): Observable<any> {
        const url = this.getUrlPdf(quotation);
        return new Observable<any>(observer => {
            this.http.get(url, {responseType: 'blob'}).subscribe(blob => {
                promiseBlob2Data(blob).subscribe(data => {
                    const response: any = {
                        urlViewer: this.sanitizer.bypassSecurityTrustResourceUrl('/assets/pdfjs/web/viewer.html?file=' + data.objectUrl),
                        url: data.objectUrl,
                        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(url),
                        base64: data.base64
                    };
                    observer.next(response);
                    observer.complete();
                });
            });
        });
    }

    getUrlPdf(quotation: Quotation) {
        return `${environment.endpointApi}/public/pdf/quotation/${quotation.id}/${quotation.serie}-${quotation.numero}`;
    }

    sendEmail(model): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/quotation/send-email`, model);
    }

    findById(id): Observable<Quotation> {
        return this.http.get(`${this.baseUrl}/quotation/${id}`);
    }

    editContactById(id, model): Observable<void> {
        return this.http.patch<void>(`${this.baseUrl}/quotation/${id}/contact-data`, model);
    }

    anular(id): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/quotation/${id}`);
    }
}
