import {Component, OnInit} from '@angular/core';
import {environment} from '@env/environment';

@Component({
    selector: 'app-footer',
    template: `
        <div class="layout-footer">
			<div class="logo-text">
                <img [src]="urlImageLogo" alt="mirage-layout" />
				<div class="text">
                    <h1>Teki Soluciones</h1>
                    <span>Aplicaciones de alto rendimiento</span>
				</div>
			</div>
			<div class="icons">
				<div class="icon icon-hastag">
					<i class="pi pi-home"></i>
				</div>
				<div class="icon icon-twitter">
					<i class="pi pi-globe"></i>
				</div>
				<div class="icon icon-prime">
					<i class="pi pi-bookmark"></i>
				</div>
			</div>
        </div>
    `
})
export class AppFooterComponent implements OnInit {
    urlImageLogo: string;
    ngOnInit(): void {
        this.urlImageLogo = environment.urlLogo;
    }

}
