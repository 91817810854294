import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Certificate} from '../domain/certificate';
import {getBaseUrl} from './util';


@Injectable({
    providedIn: 'root'
})
export class CertificateService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(idEmpresa, params: string, modeRoot: boolean): Observable<Page<Certificate>> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<Page<Certificate>>(`${baseUrl}/certificates?${params}`);
    }

    findAll(idEmpresa, modeRoot: boolean): Observable<Certificate[]> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<Certificate[]>(`${baseUrl}/certificates/all`);
    }

    findById(idEmpresa, id: number, modeRoot: boolean): Observable<Certificate> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.get<Certificate>(`${baseUrl}/certificates/${id}`);
    }

    save(idEmpresa, model: Certificate, modeRoot: boolean): Observable<Certificate> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.post<Certificate>(`${baseUrl}/certificates`, model);
    }

    update(idEmpresa, model: Certificate, modeRoot: boolean): Observable<Certificate> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.put<Certificate>(`${baseUrl}/certificates`, model);
    }

    deleteById(idEmpresa, id: number, modeRoot: boolean): Observable<void> {
        const baseUrl = getBaseUrl(idEmpresa, modeRoot);
        return this.http.delete<void>(`${baseUrl}/certificates/${id}`);
    }

    upload(idEmpresa, formData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}/files/companies/${idEmpresa}/certificados/upload`, formData);
    }

}

