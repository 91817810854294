import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Company} from '../domain/company';
import {Page} from '../domain/page';
import {ConfigCompany} from '@app/domain/config-company';
import {CompanyControl} from "@app/domain/company-control";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string): Observable<Page<Company>> {
        return this.http.get<Page<Company>>(`${this.baseUrl}/root/companies?${params}`);
    }

    findAll(): Observable<Company[]> {
        return this.http.get<Company[]>(`${this.baseUrl}/root/companies/all`);
    }

    findAllCertificadoVencido(): Observable<Company[]> {
        return this.http.get<Company[]>(`${this.baseUrl}/root/companies-monitor-old-certificate`);
    }

    findAllMonitorExcluded(): Observable<Company[]> {
        return this.http.get<Company[]>(`${this.baseUrl}/root/companies-monitor-excluded`);
    }

    findById(id: number): Observable<Company> {
        return this.http.get<Company>(`${this.baseUrl}/root/companies/${id}`);
    }

    save(model: Company): Observable<Company> {
        return this.http.post<Company>(`${this.baseUrl}/root/companies`, model);
    }

    update(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/root/companies`, model);
    }

    changeMode(model: Company): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/root/companies/modoProduccion/cambiar`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/root/companies/${id}`);
    }


    // NO ROOT

    findAllAttachedPage(params: string): Observable<Page<Company>> {
        return this.http.get<Page<Company>>(`${this.baseUrl}/attached-companies?${params}&paginacion=true`);
    }

    findAllAttached(): Observable<Company[]> {
        return this.http.get<Company[]>(`${this.baseUrl}/attached-companies?&paginacion=false`);
    }

    findAttachedById(id: number): Observable<Company> {
        return this.http.get<Company>(`${this.baseUrl}/attached-companies/${id}`);
    }

    saveAttached(model: Company): Observable<Company> {
        return this.http.post<Company>(`${this.baseUrl}/attached-companies`, model);
    }

    updateAttached(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/attached-companies`, model);
    }

    deleteAttachedById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/attached-companies/${id}`);
    }

    updateBasic(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/companies/basic`, model);
    }

    updateLogo(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/companies/logo`, model);
    }

    updateConfig(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/companies/config`, model);
    }

   getConfig(): Observable<ConfigCompany> {
        return this.http.get<ConfigCompany>(`${this.baseUrl}/companies/config`);
    }

    publicCompanyByRuc(ruc): Observable<Company> {
        return this.http.get<Company>(`${this.baseUrl}/public/companies/${ruc}`);
    }

    updateModoDescripcionLarga(): Observable<boolean> {
        return this.http.patch<boolean>(`${this.baseUrl}/companies/modo-descripcion-larga`, {});
    }

    updateEstadoServicio(model: Company): Observable<Company> {
        return this.http.put<Company>(`${this.baseUrl}/root/companies/estadoServicio`, model);
    }

    findAllCompaniesControls(params: string): Observable<Page<CompanyControl>> {
        return this.http.get<Page<CompanyControl>>(`${this.baseUrl}/root/companies-control?${params}`);
    }

}
