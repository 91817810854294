import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';
import {Page} from '../domain/page';
import {Serie} from '../domain/serie';

@Injectable({
    providedIn: 'root'
})
export class SerieService {

    baseUrl: string = environment.endpointApi;

    constructor(private http: HttpClient) {
    }

    findAllPage(params: string): Observable<Page<Serie>> {
        return this.http.get<Page<Serie>>(`${this.baseUrl}/series?${params}`);
    }

    findAll(): Observable<Serie[]> {
        return this.http.get<Serie[]>(`${this.baseUrl}/series/all`);
    }

    findAllByPuntoVenta(idPuntoVenta): Observable<Serie[]> {
        return this.http.get<Serie[]>(`${this.baseUrl}/series/office/${idPuntoVenta}`);
    }

    findAllByPuntoVentaAndTipoComprobante(idPuntoVenta, tipoComprobante): Observable<Serie[]> {
        return this.http.get<Serie[]>(`${this.baseUrl}/series/office/${idPuntoVenta}?tipoDocumento=${tipoComprobante}`);
    }

    findById(id: number): Observable<Serie> {
        return this.http.get<Serie>(`${this.baseUrl}/series/${id}`);
    }

    save(model: Serie): Observable<Serie> {
        return this.http.post<Serie>(`${this.baseUrl}/series`, model);
    }

    update(model: Serie): Observable<Serie> {
        return this.http.put<Serie>(`${this.baseUrl}/series`, model);
    }

    deleteById(id: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/series/${id}`);
    }
}
